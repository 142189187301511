<template>
  <v-row :key="tempKey" no-gutters>
    <v-col cols="12">
      <v-form
        ref="form"
        v-model="valid"
      >

        <v-row v-if="type === 'JDBC'" wrap no-gutters>
          <v-col cols="12">
            <div class="d-inline-flex" style="width: 100%">
              <v-autocomplete
                v-model="properties.credentialName"
                data-cy="jdbc-credentialname"
                outlined
                dense
                :items="credentials"
                :loading="isLoadingCredentials"
                :search-input.sync="searchCredentials"
                clearable
                hide-no-data
                item-text="name"
                item-value="name"
                :label="$lang.labels.credential"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                :readonly="!canEdit"
                class="required-asterisk"
              ></v-autocomplete>
              <v-btn
                icon
                light
                color="primary"
                class="ml-1"
                @click="searchCredentialsFunction(searchCredentials)"
              >
                <v-icon
                  dense
                  small
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
              <v-btn
                text
                class="ml-1"
                color="primary"
                :disabled="!properties.credentialName"
                @click="openCredential()"
              >
                {{ $lang.actions.openTemplate }}
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12">
            <v-select
              v-if="!['BEGIN TRANSACTION', 'COMMIT TRANSACTION', 'ROLLBACK TRANSACTION'].includes(properties.action)"
              v-model="properties.action"
              :items="actions.jdbc"
              :label="$lang.labels.action"
              outlined
              dense
              required
              data-cy="jdbc-action"
              :readonly="!canEdit"
              class="required-asterisk"
              @change="fakeJdbcAction = properties.action"
            ></v-select>
            <v-select
              v-else
              v-model="fakeJdbcAction"
              :items="actions.jdbc"
              :label="$lang.labels.action"
              outlined
              dense
              required
              data-cy="jdbc-action"
              :readonly="!canEdit"
              class="required-asterisk"
              @change="properties.action = fakeJdbcAction"
            ></v-select>
          </v-col>
          <v-col v-if="!['BEGIN TRANSACTION', 'COMMIT TRANSACTION', 'ROLLBACK TRANSACTION'].includes(properties.action)" cols="12" style="position: relative">
            <v-textarea
              v-model="properties.query"
              data-cy="jdbc-query"
              rows="2"
              outlined
              dense
              :label="$lang.labels.query"
              required
              :rules="[v => !!v || $lang.labels.required]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-textarea>
            <v-btn
              class="button-help"
              style="position: absolute; top: 10px; right: 10px"
              icon
              small
              :disabled="!canEdit"
              @click="selectedItem = { text: '', value: properties.query, parentType: 'query' }; showEditor = true"
            >
              <v-icon small color="info">mdi-pencil</v-icon>
            </v-btn>
          </v-col>
          <v-col v-if="!['BEGIN TRANSACTION', 'COMMIT TRANSACTION', 'ROLLBACK TRANSACTION'].includes(properties.action)" :cols="properties.action === 'SELECT' ? '8' : '12'">
            <v-text-field
              v-model="properties.targetObject"
              data-cy="jdbc-targetobject"
              outlined
              dense
              :label="$lang.labels.targetObject"
              :rules="[(v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col v-if="properties.action === 'SELECT'" cols="4">
            <v-switch v-model="properties.singleResult" :label="$lang.labels.singleResult" class="pl-3 mt-1"/>
          </v-col>
        </v-row>

        <v-row v-if="type === 'MONGODB'" wrap no-gutters>

          <v-col cols="12">
            <div class="d-inline-flex" style="width: 100%">
              <v-autocomplete
                v-model="properties.credentialName"
                outlined
                dense
                :items="credentials"
                :loading="isLoadingCredentials"
                :search-input.sync="searchCredentials"
                clearable
                hide-no-data
                item-text="name"
                item-value="name"
                :label="$lang.labels.credential"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                :readonly="!canEdit"
                class="required-asterisk"
                data-cy="mongodb-credentialName"
              ></v-autocomplete>
              <v-btn
                icon
                light
                color="primary"
                class="ml-1"
                @click="searchCredentialsFunction(searchCredentials)"
              >
                <v-icon
                  dense
                  small
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
              <v-btn
                text
                class="ml-1"
                color="primary"
                :disabled="!properties.credentialName"
                @click="openCredential()"
              >
                {{ $lang.actions.openTemplate }}
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12">
            <v-select
              v-model="properties.action"
              :items="actions.mongoDb"
              :label="$lang.labels.action"
              outlined
              dense
              required
              :readonly="!canEdit"
              class="required-asterisk"
              data-cy="mongodb-action"
              @change="properties.query = null; properties.queryUpdate = null"
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="properties.collectionName"
              outlined
              dense
              :label="$lang.labels.collectionName"
              required
              :rules="[v => !!v || $lang.labels.required]"
              :readonly="!canEdit"
              class="required-asterisk"
              data-cy="mongodb-collectionName"
            ></v-text-field>
          </v-col>
          <v-col v-if="['FIND', 'UPDATE', 'DELETE', 'COUNT'].includes(properties.action)" cols="12" style="position: relative">
            <v-textarea
              v-model="properties.parameters.query"
              rows="2"
              outlined
              dense
              :label="$lang.labels.query"
              required
              :rules="[v => !!v || $lang.labels.required]"
              :readonly="!canEdit"
              class="required-asterisk"
              data-cy="mongodb-query"
            ></v-textarea>
            <v-btn
              class="button-help"
              style="position: absolute; top: 10px; right: 10px"
              icon
              small
              :disabled="!canEdit"
              @click="selectedItem = { text: 'query', value: properties.parameters.query, parentType: 'parameters' }; showEditor = true"
            >
              <v-icon small color="info">mdi-pencil</v-icon>
            </v-btn>
          </v-col>
          <v-col v-if="['INSERT', 'UPDATE'].includes(properties.action)" cols="12" style="position: relative">
            <v-text-field
              v-model="properties.parameters.queryUpdate"
              outlined
              dense
              :label="$lang.labels.queryUpdate"
              required
              :rules="[v => !!v || $lang.labels.required]"
              :readonly="!canEdit"
              class="required-asterisk"
              data-cy="mongodb-queryUpdate"
            ></v-text-field>
            <v-btn
              class="button-help"
              style="position: absolute; top: 5px; right: 10px"
              icon
              small
              :disabled="!canEdit"
              @click="selectedItem = { text: 'queryUpdate', value: properties.parameters.queryUpdate, parentType: 'parameters' }; showEditor = true"
            >
              <v-icon small color="info">mdi-pencil</v-icon>
            </v-btn>
          </v-col>
          <v-col v-if="['FIND'].includes(properties.action)" cols="12" style="position: relative">
            <v-textarea
              v-model="properties.parameters.projection"
              rows="2"
              outlined
              dense
              :label="$lang.labels.projection"
              :readonly="!canEdit"
              data-cy="mongodb-projection"
            ></v-textarea>
            <v-btn
              class="button-help"
              style="position: absolute; top: 10px; right: 10px"
              icon
              small
              :disabled="!canEdit"
              @click="selectedItem = { text: 'projection', value: properties.parameters.projection, parentType: 'parameters' }; showEditor = true"
            >
              <v-icon small color="info">mdi-pencil</v-icon>
            </v-btn>
          </v-col>

          <v-col v-if="['FIND'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.skip"
              outlined
              dense
              :label="$lang.labels.skip"
              :readonly="!canEdit"
              data-cy="mongodb-skip"
            ></v-text-field>
          </v-col>

          <v-col v-if="['FIND'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.limit"
              outlined
              dense
              :label="$lang.labels.limit"
              :readonly="!canEdit"
              data-cy="mongodb-limit"
            ></v-text-field>
          </v-col>

          <v-col v-if="['FIND'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.sort.field"
              outlined
              dense
              :label="$lang.labels.sortField"
              :readonly="!canEdit"
              data-cy="mongodb-sort-field"
            ></v-text-field>
          </v-col>

          <v-col v-if="['FIND'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.sort.direction"
              outlined
              dense
              :label="$lang.labels.sortDirection"
              :readonly="!canEdit"
              data-cy="mongodb-sort-direction"
            ></v-text-field>
          </v-col>

          <v-col :cols="properties.action === 'FIND' ? '8' : '12'">
            <v-text-field
              v-model="properties.targetObject"
              outlined
              dense
              :label="$lang.labels.targetObject"
              :rules="[(v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
              :readonly="!canEdit"
              data-cy="mongodb-targetObject"
            ></v-text-field>
          </v-col>

          <v-col v-if="properties.action === 'FIND'" cols="4">
            <v-switch v-model="properties.singleResult" :label="$lang.labels.singleResult" class="pl-3 mt-1"/>
          </v-col>
        </v-row>

        <v-row v-if="type === 'FOREACH'" wrap no-gutters>
          <v-col cols="12">
            <v-text-field
              v-model="properties.query"
              outlined
              dense
              :label="$lang.labels.query"
              required
              :rules="[v => !!v || $lang.labels.required]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="properties.recordName"
              outlined
              dense
              :label="$lang.labels.recordName"
              required
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="type === 'EXECUTE_PROCESS'" wrap no-gutters>
          <v-col :cols="properties.action !== 'INLINE' ? '9' : '12'">
            <v-select
              v-model="properties.action"
              :items="formattedExecuteProcessActions"
              :label="$lang.labels.action"
              outlined
              dense
              required
              :readonly="!canEdit"
              class="required-asterisk"
              data-cy="executeProcess-action"
              @change="properties.action === 'ASYNC' ? properties.output = {} : '', properties.action === 'INLINE' ? properties.priority = '' : properties.priority = 64, codeKey++"
            ></v-select>
          </v-col>
          <v-col
            v-if="properties.action !== 'INLINE'"
            cols="3"
            class="pl-sm-1"
          >
            <v-select
              v-model="properties.priority"
              :items="priorities"
              :label="$lang.labels.priority"
              outlined
              dense
              required
              :readonly="!canEdit"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <div class="d-inline-flex justify-space-between" style="width: 100%">
              <v-autocomplete
                v-model="properties.processName"
                outlined
                dense
                :items="processes"
                :loading="isLoadingProcesses"
                :search-input.sync="searchProcesses"
                clearable
                hide-no-data
                item-text="name"
                item-value="name"
                :label="$lang.labels.process"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                :readonly="!canEdit"
                class="mr-1"
                :hint="showProcessNameHint && properties?.processName ? `${$lang.hints.processNameNoExists}: ${properties.processName}` : ''"
                persistent-hint
                @change="onChangeProcessName()"
              ></v-autocomplete>
              <v-btn
                text
                class="mx-0"
                color="primary"
                :disabled="!processId"
                @click="openProcess()"
              >
                {{ $lang.actions.openProcess }}
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="properties.childEventIdPath"
              outlined
              dense
              :label="$lang.labels.childEventIdPath"
              required
              :rules="[(v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="py-1">
            <custom-external-process-input-key-value
              v-if="showExecuteInputs && properties.processName"
              :key="codeKey + 500"
              type="input"
              :step-type="type"
              :can-edit="canEdit"
              :data="properties.input"
              :key-list="executeInputsKeyList"
              @dataChanged="properties.input = $event"
            />
            <v-progress-circular v-if="!showExecuteInputs && properties.processName" indeterminate color="primary"></v-progress-circular>
          </v-col>
          <v-col v-if="properties.action !== 'ASYNC' && properties.processName" cols="12" class="py-1">
            <custom-external-process-input-key-value
              v-if="showExecuteOutputs && properties.processName"
              :key="codeKey + 500"
              type="output"
              :step-type="type"
              :can-edit="canEdit"
              :data="properties.output"
              :key-list="executeOutputsKeyList"
              @dataChanged="properties.output = $event"
            />
            <v-progress-circular v-if="!showExecuteOutputs && properties.processName" indeterminate color="primary"></v-progress-circular>
          </v-col>
        </v-row>

        <v-row v-if="type === 'JS'" wrap no-gutters>
          <v-col cols="12" class="pb-2">
            <div class="d-inline-flex" style="width: 100%">
              <v-autocomplete
                v-model="properties.credentialName"
                data-cy="js-credentialname"
                outlined
                dense
                :items="credentials"
                :loading="isLoadingCredentials"
                :search-input.sync="searchCredentials"
                clearable
                hide-no-data
                hide-details
                item-text="name"
                item-value="name"
                :label="$lang.labels.credential"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                :readonly="!canEdit"
              ></v-autocomplete>
              <v-btn
                icon
                light
                color="primary"
                class="ml-1"
                @click="searchCredentialsFunction(searchCredentials)"
              >
                <v-icon
                  dense
                  small
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
              <v-btn
                text
                class="ml-1"
                color="primary"
                :disabled="!properties.credentialName"
                @click="openCredential()"
              >
                {{ $lang.actions.openTemplate }}
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12" class="pb-2">
            <v-autocomplete
              v-model="properties.librariesNames"
              data-cy="js-librariesNames"
              outlined
              dense
              :items="librariesFormatted"
              :loading="isLoadingLibraries"
              :search-input.sync="searchLibraries"
              multiple
              chips
              hide-no-data
              hide-details
              item-text="name"
              item-value="name"
              :label="$lang.labels.libraries"
              :placeholder="$lang.actions.startTyping"
              prepend-inner-icon="mdi-cloud-search-outline"
              :readonly="!canEdit"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" class="py-1">
            <add-key-value
              :key="codeKey"
              :step-type="type"
              type="js"
              :can-edit="canEdit"
              :steps="steps"
              :single-step="step"
              :data="properties.set"
              @editor="openEditor($event, 'set', type)"
              @dataChanged="properties.set = $event"
            ></add-key-value>
          </v-col>
        </v-row>

        <v-row v-if="type === 'GROOVY'" wrap no-gutters>
          <v-col cols="12" class="pb-2">
            <div class="d-inline-flex" style="width: 100%">
              <v-autocomplete
                v-model="properties.credentialName"
                data-cy="groovy-credentialname"
                outlined
                dense
                :items="credentials"
                :loading="isLoadingCredentials"
                :search-input.sync="searchCredentials"
                clearable
                hide-no-data
                hide-details
                item-text="name"
                item-value="name"
                :label="$lang.labels.credential"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                :readonly="!canEdit"
              ></v-autocomplete>
              <v-btn
                icon
                light
                color="primary"
                class="ml-1"
                @click="searchCredentialsFunction(searchCredentials)"
              >
                <v-icon
                  dense
                  small
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
              <v-btn
                text
                class="ml-1"
                color="primary"
                :disabled="!properties.credentialName"
                @click="openCredential()"
              >
                {{ $lang.actions.openTemplate }}
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12" class="pb-2">
            <v-autocomplete
              v-model="properties.librariesNames"
              data-cy="groovy-librariesNames"
              outlined
              dense
              :items="librariesFormatted"
              :loading="isLoadingLibraries"
              :search-input.sync="searchLibraries"
              multiple
              chips
              hide-no-data
              hide-details
              item-text="name"
              item-value="name"
              :label="$lang.labels.libraries"
              :placeholder="$lang.actions.startTyping"
              prepend-inner-icon="mdi-cloud-search-outline"
              :readonly="!canEdit"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" class="py-1">
            <add-key-value
              :key="codeKey"
              :step-type="type"
              type="groovy"
              :steps="steps"
              :can-edit="canEdit"
              :data="properties.set"
              :single-step="step"
              @editor="openEditor($event, 'set', type)"
              @dataChanged="properties.set = $event"
            ></add-key-value>
          </v-col>
        </v-row>

        <v-row v-if="type === 'SWITCH'" wrap no-gutters>
          <v-col cols="12">
            <v-select
              v-model="properties.scriptEngine"
              :items="formattedScriptEngines"
              :label="$lang.labels.scriptEngine"
              outlined
              dense
              required
              data-cy="switch-scriptEngine"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-select>
          </v-col>
          <v-col cols="12" class="pb-2">
            <h3>Select Query to edit steps</h3>
          </v-col>
        </v-row>

        <v-row v-if="type === 'EMAIL'" wrap no-gutters>
          <v-col cols="12">
            <v-select
              v-model="properties.async"
              :items="formattedSmtpTypes"
              :label="$lang.labels.type"
              outlined
              dense
              required
              :readonly="!canEdit"
              class="required-asterisk"
              data-cy="smtp-action"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <div class="d-inline-flex" style="width: 100%">
              <v-autocomplete
                v-model="properties.credentialName"
                outlined
                dense
                :items="credentials"
                :loading="isLoadingCredentials"
                :search-input.sync="searchCredentials"
                clearable
                hide-no-data
                item-text="name"
                item-value="name"
                required
                :rules="[v => !!v || $lang.labels.required]"
                :label="$lang.labels.credential"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                :readonly="!canEdit"
                class="required-asterisk"
              ></v-autocomplete>
              <v-btn
                icon
                light
                color="primary"
                class="ml-1"
                @click="searchCredentialsFunction(searchCredentials)"
              >
                <v-icon
                  dense
                  small
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
              <v-btn
                text
                class="ml-1"
                color="primary"
                :disabled="!properties.credentialName"
                @click="openCredential()"
              >
                {{ $lang.actions.openTemplate }}
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="properties.from"
              outlined
              dense
              :label="$lang.labels.from"
              :rules="[(v) => $options.filters.javaVariableConventionRules(v, true) || emailRules(v) || 'Wrong format']"
              :readonly="!canEdit"
              data-cy="smtp-from"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="properties.to"
              outlined
              dense
              :label="$lang.labels.to"
              required
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || emailRules(v) || 'Wrong format']"
              :readonly="!canEdit"
              class="required-asterisk"
              data-cy="smtp-to"
            ></v-text-field>
          </v-col>

          <v-col cols="12" class="py-1">
            <add-vals
              v-if="showCc"
              :key="codeKey + 98000"
              type="cc"
              :step-type="type"
              :can-edit="canEdit"
              :data="properties.cc"
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || emailRules(v) || 'Wrong format']"
              @dataChanged="properties.cc = $event"
              @hide="showCc = false; properties.cc = []"
            ></add-vals>
            <p v-else class="pl-1 cursor-pointer color-primary" @click="showCc = true">{{ $lang.actions.addCc }}</p>
          </v-col>

          <v-col cols="12" class="py-1">
            <add-vals
              v-if="showBcc"
              :key="codeKey + 89000"
              type="bcc"
              :step-type="type"
              :can-edit="canEdit"
              :data="properties.bcc"
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || emailRules(v) || 'Wrong format']"
              @dataChanged="properties.bcc = $event"
              @hide="showBcc = false; properties.bcc = []"
            ></add-vals>
            <p v-else class="pl-1 cursor-pointer color-primary" @click="showBcc = true">{{ $lang.actions.addBcc }}</p>
          </v-col>

          <v-col cols="12" class="pt-2">
            <div class="d-inline-flex" style="width: 100%">
              <v-autocomplete
                v-model="properties.textTemplate"
                outlined
                dense
                :items="templates"
                :loading="isLoadingTemplates"
                :search-input.sync="searchTemplates"
                clearable
                hide-no-data
                item-text="name"
                item-value="name"
                required
                :rules="[v => !!v || $lang.labels.required]"
                :label="$lang.labels.textTemplate"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                :readonly="!canEdit"
                class="required-asterisk"
              ></v-autocomplete>
              <v-btn
                icon
                light
                color="primary"
                class="ml-1"
                @click="searchTextTemplatesFunction(searchTemplates)"
              >
                <v-icon
                  dense
                  small
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
              <v-btn
                text
                class="ml-1"
                color="primary"
                :disabled="!templateId"
                @click="openTemplate()"
              >
                {{ $lang.actions.openTemplate }}
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12" class="pb-1">
            <add-key-value
              :key="codeKey + 10000"
              type="variables"
              :step-type="type"
              :can-edit="canEdit"
              :data="properties.variables"
              :single-step="step"
              :text-template-name="properties.textTemplate"
              @editor="openEditor($event, 'variables')"
              @dataChanged="properties.variables = $event"
            ></add-key-value>
          </v-col>
          <v-col cols="12" class="py-1">
            <add-attachments
              :key="codeKey + 99000"
              type="attachments"
              :step-type="type"
              :can-edit="canEdit"
              :data="properties.attachments"
              @dataChanged="properties.attachments = $event"
            ></add-attachments>
          </v-col>
        </v-row>

        <v-row v-if="type === 'REST'" no-gutters>
          <v-col cols="12">
            <v-select
              v-model="properties.scriptEngine"
              :items="formattedScriptEngines"
              :label="$lang.labels.scriptEngine"
              outlined
              dense
              required
              data-cy="rest-scriptEngine"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
          >
            <v-autocomplete
              v-model="properties.encoding"
              :items="encodingTypes"
              :label="$lang.labels.encoding"
              outlined
              dense
              required
              :rules="[v => !!v || $lang.labels.required]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="properties.authType"
              :items="formattedAuthTypes"
              :label="$lang.labels.authType"
              outlined
              dense
              required
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <div class="d-inline-flex" style="width: 100%">
              <v-combobox
                v-model="properties.credentialName"
                outlined
                dense
                :items="credentials"
                :loading="isLoadingCredentials"
                :search-input.sync="searchCredentials"
                clearable
                hide-no-data
                item-text="name"
                item-value="name"
                :label="$lang.labels.credential"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                :readonly="!canEdit"
                @input="isJavaVariable(returnNameOrString(properties.credentialName)) ? '' : credentials.map((x) => x.name).includes(returnNameOrString(properties.credentialName)) ? '' : properties.credentialName = ''"
              ></v-combobox>
              <v-btn
                icon
                light
                color="primary"
                class="ml-1"
                @click="searchCredentialsFunction(searchCredentials)"
              >
                <v-icon
                  dense
                  small
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
              <v-btn
                text
                class="ml-1"
                color="primary"
                :disabled="!properties.credentialName"
                @click="openCredential()"
              >
                {{ $lang.actions.openTemplate }}
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="properties.action"
              :items="actions.rest"
              :label="$lang.labels.action"
              outlined
              dense
              required
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-select>
          </v-col>
          <v-col cols="12" class="py-1">
            <add-key-value
              :key="codeKey + 10000"
              type="body"
              :steps="steps"
              :step-type="type"
              :can-edit="canEdit"
              :single-step="step"
              :data="{body: properties.body || {}, bodyScript: properties.bodyScript || '', bodyTemplate: properties.bodyTemplate || ''}"
              @editor="openEditor($event, 'body')"
              @bodyScriptEditor="openEditor($event, 'bodyScript')"
              @dataChanged="properties.body = $event"
              @dataBodyScriptChanged="properties.bodyScript = $event"
              @dataBodyTemplateChanged="properties.bodyTemplate = $event"
            ></add-key-value>
          </v-col>
          <v-col cols="12" class="py-1">
            <add-key-value
              :key="codeKey + 30000"
              type="headers"
              :steps="steps"
              :step-type="type"
              :can-edit="canEdit"
              :single-step="step"
              :data="properties.headers"
              @editor="openEditor($event, 'headers')"
              @dataChanged="properties.headers = $event"
            ></add-key-value>
          </v-col>
          <v-col cols="12" class="py-1">
            <add-key-value
              :key="codeKey + 50000"
              type="queryParams"
              :steps="steps"
              :step-type="type"
              :can-edit="canEdit"
              :single-step="step"
              :data="properties.queryParams"
              @editor="openEditor($event, 'queryParams')"
              @dataChanged="properties.queryParams = $event"
            ></add-key-value>
          </v-col>
          <v-col cols="12" class="pt-1">
            <v-text-field
              v-model="properties.restResponseCode"
              outlined
              dense
              :label="$lang.labels.restResponseCode"
              required
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="properties.targetObject"
              outlined
              dense
              :label="$lang.labels.restResponseContentTargetObject"
              required
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="position-relative">
            <v-text-field
              v-model="properties.targetUrl"
              outlined
              dense
              :label="$lang.labels.targetUrl"
              required
              :rules="[v => !!v || $lang.labels.required]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-text-field>
            <v-btn
              class="button-help"
              style="position: absolute; top: 6px; right: 4px"
              icon
              small
              :disabled="!canEdit"
              @click="selectedItem = { text: '', value: properties.targetUrl, parentType: 'targetUrl' }; showEditor = true"
            >
              <v-icon small color="info">mdi-pencil</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="9">
            <v-text-field
              v-model="tempTimeout"
              outlined
              dense
              :label="$lang.labels.changeableTimeout"
              required
              :rules="[v => !!v || $lang.labels.required]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="timeUnit"
              :items="timeUnits"
              :label="$lang.labels.unit"
              outlined
              dense
              required
              class="pl-2"
              :readonly="!canEdit"
            ></v-select>
          </v-col>
        </v-row>

        <v-row v-if="type === 'WHILE'" wrap no-gutters>
          <v-col cols="12">
            <v-select
              v-model="properties.scriptEngine"
              :items="formattedScriptEngines"
              :label="$lang.labels.scriptEngine"
              outlined
              dense
              required
              data-cy="while-scriptEngine"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-select>
          </v-col>
          <v-col cols="12" class="pt-1">
            <v-text-field
              v-model="properties.query"
              outlined
              dense
              :label="$lang.labels.query"
              required
              :rules="[v => !!v || $lang.labels.required]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="type === 'SLACK'" wrap no-gutters>
          <v-col cols="12">
            <div class="d-inline-flex" style="width: 100%">
              <v-autocomplete
                v-model="properties.credentialName"
                outlined
                dense
                :items="credentials"
                :loading="isLoadingCredentials"
                :search-input.sync="searchCredentials"
                clearable
                hide-no-data
                item-text="name"
                item-value="name"
                :label="$lang.labels.credential"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                :readonly="!canEdit"
              ></v-autocomplete>
              <v-btn
                icon
                light
                color="primary"
                class="ml-1"
                @click="searchCredentialsFunction(searchCredentials)"
              >
                <v-icon
                  dense
                  small
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
              <v-btn
                text
                class="ml-1"
                color="primary"
                :disabled="!properties.credentialName"
                @click="openCredential()"
              >
                {{ $lang.actions.openTemplate }}
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" class="pt-1">
            <v-text-field
              v-model="properties.channelId"
              outlined
              dense
              :label="$lang.labels.channelId"
              :readonly="!canEdit"
              :rules="[(v) => checkRegexFieldRecipient(v) || $lang.errors.wrongSearchParam]"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-select
              v-model="properties.scriptEngine"
              :items="formattedScriptEngines"
              :label="$lang.labels.scriptEngine"
              outlined
              dense
              data-cy="twilio-scriptEngine"
              :readonly="!canEdit"
              class="required-asterisk"
              :rules="[v => !!v || $lang.labels.required]"
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-switch
              v-model="isTemplate"
              :label="$lang.labels.template"
              @change="isTemplate ? properties.message = '' : ''"
            ></v-switch>
          </v-col>
          <v-col v-if="!isTemplate" cols="12" class="pt-1 position-relative">
            <v-text-field
              v-model="properties.message"
              outlined
              dense
              :label="$lang.labels.message"
              required
              :rules="[v => !!v || $lang.labels.required, (v) => checkRegexFieldRecipient(v) || $lang.errors.wrongSearchParam]"
              class="required-asterisk"
              :readonly="!canEdit"
            ></v-text-field>
            <v-btn
              class="button-help"
              style="position: absolute; top: 13px; right: 6px"
              icon
              small
              :disabled="!canEdit"
              @click="selectedItem = { text: '', value: properties.message, parentType: 'message' }; showSlackEditor = true"
            >
              <v-icon small color="info">mdi-pencil</v-icon>
            </v-btn>
          </v-col>
          <v-col v-if="isTemplate" cols="12">
            <div class="d-inline-flex" style="width: 100%">
              <v-autocomplete
                v-model="properties.textTemplate"
                outlined
                dense
                :items="templates"
                :loading="isLoadingTemplates"
                :search-input.sync="searchTemplates"
                clearable
                hide-no-data
                item-text="name"
                item-value="name"
                :label="$lang.labels.textTemplate"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                :readonly="!canEdit"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
              ></v-autocomplete>
              <v-btn
                icon
                light
                color="primary"
                class="ml-1"
                @click="searchTextTemplatesFunction(searchTemplates)"
              >
                <v-icon
                  dense
                  small
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
              <v-btn
                text
                class="ml-1"
                color="primary"
                :disabled="!templateId"
                @click="openTemplate()"
              >
                {{ $lang.actions.openTemplate }}
              </v-btn>
            </div>
          </v-col>
          <v-col v-if="properties.textTemplate && isTemplate" cols="12" class="pb-2">
            <add-key-value
              type="variables"
              :step-type="type"
              :can-edit="canEdit"
              :data="properties.variables"
              :single-step="step"
              :text-template-name="properties.textTemplate"
              @dataChanged="properties.variables = $event"
            ></add-key-value>
          </v-col>
        </v-row>

        <v-row v-if="type === 'JWT'" wrap no-gutters>
          <v-col cols="12">
            <div class="d-inline-flex" style="width: 100%">
              <v-autocomplete
                v-model="properties.credentialName"
                outlined
                dense
                :items="credentials"
                :loading="isLoadingCredentials"
                :search-input.sync="searchCredentials"
                clearable
                hide-no-data
                item-text="name"
                item-value="name"
                :label="$lang.labels.credential"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                :readonly="!canEdit"
              ></v-autocomplete>
              <v-btn
                icon
                light
                color="primary"
                class="ml-1"
                @click="searchCredentialsFunction(searchCredentials)"
              >
                <v-icon
                  dense
                  small
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
              <v-btn
                text
                class="ml-1"
                color="primary"
                :disabled="!properties.credentialName"
                @click="openCredential()"
              >
                {{ $lang.actions.openTemplate }}
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" class="pb-2">
            <add-key-value
              :key="codeKey"
              type="payload"
              :step-type="type"
              :can-edit="canEdit"
              :data="properties.payload"
              :single-step="step"
              @editor="openEditor($event, 'payload')"
              @dataChanged="properties.payload = $event"
            ></add-key-value>
          </v-col>
          <v-col cols="12" class="pt-1">
            <v-text-field
              v-model="properties.targetObject"
              outlined
              dense
              :label="$lang.labels.targetObject"
              required
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="subType === 'QUERY'" wrap no-gutters>
          <v-col cols="12" class="pt-1" style="position: relative">
            <v-textarea
              v-model="properties.query"
              data-cy="switch-query"
              rows="4"
              outlined
              dense
              :label="$lang.labels.query"
              required
              :rules="[v => !!v || $lang.labels.required]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-textarea>
            <v-btn
              class="button-help"
              style="position: absolute; top: 18px; right: 10px"
              icon
              small
              :disabled="!canEdit"
              @click="selectedItem = { text: '', value: properties.query, parentType: 'query' }; showEditor = true"
            >
              <v-icon small color="info">mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              class="button-help"
              style="position: absolute; top: 18px; right: 42px"
              text
              small
              color="info"
              :disabled="!canEdit"
              @click="properties.query = 'else'"
            >
              else
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="properties.name"
              outlined
              dense
              :label="$lang.labels.optionalQueryName"
              :readonly="!canEdit"
              data-cy="switch-name"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="type === 'PROCESS_SETTING'" wrap no-gutters>
          <v-col cols="12">
            <div class="d-inline-flex" style="width: 100%">
              <v-autocomplete
                v-model="properties.processSettingName"
                outlined
                dense
                :items="settings"
                :loading="isLoadingProcessSetting"
                :search-input.sync="searchProcessSetting"
                clearable
                hide-no-data
                item-text="name"
                item-value="name"
                :label="$lang.labels.processSettingName"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                :readonly="!canEdit"
                data-cy="processSettings-credentialName"
                class="required-asterisk"
              ></v-autocomplete>
              <v-btn
                icon
                light
                color="primary"
                class="ml-1"
                @click="searchProcessesSettings(searchProcessSetting)"
              >
                <v-icon
                  dense
                  small
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
              <v-btn
                text
                class="ml-1"
                color="primary"
                :disabled="!properties.processSettingName"
                @click="openProcessSetting()"
              >
                {{ $lang.actions.openTemplate }}
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="properties.targetObject"
              outlined
              dense
              :label="$lang.labels.targetObject"
              data-cy="processSettings-targetObject"
              required
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="type === 'TWILIO'" wrap no-gutters>
          <v-col cols="12">
            <div class="d-inline-flex" style="width: 100%">
              <v-autocomplete
                v-model="properties.credentialName"
                outlined
                dense
                :items="credentials"
                :loading="isLoadingCredentials"
                :search-input.sync="searchCredentials"
                clearable
                hide-no-data
                item-text="name"
                item-value="name"
                :label="$lang.labels.credential"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                :readonly="!canEdit"
                class="required-asterisk"
                :rules="[v => !!v || $lang.labels.required]"
              ></v-autocomplete>
              <v-btn
                icon
                light
                color="primary"
                class="ml-1"
                @click="searchCredentialsFunction(searchCredentials)"
              >
                <v-icon
                  dense
                  small
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
              <v-btn
                text
                class="ml-1"
                color="primary"
                :disabled="!properties.credentialName"
                @click="openCredential()"
              >
                {{ $lang.actions.openTemplate }}
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12">
            <v-select
              v-model="properties.scriptEngine"
              :items="formattedScriptEngines"
              :label="$lang.labels.scriptEngine"
              outlined
              dense
              data-cy="twilio-scriptEngine"
              :readonly="!canEdit"
              class="required-asterisk"
              :rules="[v => !!v || $lang.labels.required]"
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-switch v-model="isTemplate" :label="$lang.labels.template"></v-switch>
          </v-col>
          <v-col v-if="!isTemplate" cols="12" class="pt-1 position-relative">
            <v-text-field
              v-model="properties.message"
              outlined
              dense
              :label="$lang.labels.message"
              required
              :rules="[v => !!v || $lang.labels.required, (v) => checkRegexFieldRecipient(v) || $lang.errors.wrongSearchParam]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-text-field>
            <v-btn
              class="button-help"
              style="position: absolute; top: 13px; right: 6px"
              icon
              small
              :disabled="!canEdit"
              @click="selectedItem = { text: '', value: properties.message, parentType: 'message' }; showSlackEditor = true"
            >
              <v-icon small color="info">mdi-pencil</v-icon>
            </v-btn>
          </v-col>
          <v-col v-if="isTemplate" cols="12" class="pt-1">
            <div class="d-inline-flex" style="width: 100%">
              <v-autocomplete
                v-model="properties.textTemplate"
                outlined
                dense
                :items="templates"
                :loading="isLoadingTemplates"
                :search-input.sync="searchTemplates"
                clearable
                hide-no-data
                item-text="name"
                item-value="name"
                :label="$lang.labels.textTemplate"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                :readonly="!canEdit"
                class="required-asterisk"
                :rules="[v => !!v || $lang.labels.required]"
              ></v-autocomplete>
              <v-btn
                icon
                light
                color="primary"
                class="ml-1"
                @click="searchTextTemplatesFunction(searchTemplates)"
              >
                <v-icon
                  dense
                  small
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
              <v-btn
                text
                class="ml-1"
                color="primary"
                :disabled="!templateId"
                @click="openTemplate()"
              >
                {{ $lang.actions.openTemplate }}
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" class="pt-1">
            <v-text-field
              v-model="properties.from"
              outlined
              dense
              :label="$lang.labels.from"
              required
              :rules="[v => !!v || $lang.labels.required, (v) => checkRegexFieldRecipient(v) || $lang.errors.wrongSearchParam]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pt-1">
            <v-text-field
              v-model="properties.to"
              outlined
              dense
              :label="$lang.labels.to"
              required
              :rules="[v => !!v || $lang.labels.required, (v) => checkRegexFieldRecipient(v) || $lang.errors.wrongSearchParam]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-text-field>
          </v-col>
          <v-col v-if="properties.textTemplate && isTemplate" cols="12" class="pb-2">
            <add-key-value
              type="variables"
              :step-type="type"
              :can-edit="canEdit"
              :data="properties.variables"
              :single-step="step"
              :text-template-name="properties.textTemplate"
              @dataChanged="properties.variables = $event"
            ></add-key-value>
          </v-col>
        </v-row>

        <v-row v-if="type === 'USER'" wrap no-gutters>
          <v-col cols="12">
            <v-select
              v-model="properties.action"
              :items="formattedUserActions"
              :label="$lang.labels.action"
              outlined
              dense
              required
              item-text="text"
              item-value="value"
              data-cy="user-action"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required]"
              class="required-asterisk"
            ></v-select>
          </v-col>
          <v-col v-if="properties.action === 'CREATE_USER'" cols="12">
            <v-text-field
              v-model="properties.fields.name"
              outlined
              dense
              :label="$lang.labels.name"
              required
              :rules="[v => !!v || $lang.labels.required]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-text-field>
          </v-col>
          <v-col v-if="!['GET_USER_DETAILS', 'GET_USER_ROLES', 'GET_USERS_BY_ROLES'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.fields.email"
              outlined
              dense
              :label="$lang.labels.email"
              required
              :rules="[v => !!v || $lang.labels.required]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-text-field>
          </v-col>
          <v-col v-if="properties.action === 'CREATE_USER' || properties.action === 'GET_USERS_BY_ROLES'" cols="12">
            <v-text-field
              v-model="properties.fields.roles"
              outlined
              dense
              :label="$lang.labels.roles"
              required
              :rules="[v => !!v || $lang.labels.required]"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>
          <v-col v-if="properties.action === 'VALIDATE_API_KEY'" cols="12">
            <v-text-field
              v-model="properties.fields.apiKey"
              outlined
              dense
              :label="$lang.labels.apiKey"
              required
              :rules="[v => !!v || $lang.labels.required]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-text-field>
          </v-col>
          <v-col v-if="properties.action === 'GENERATE_API_KEY' || properties.action === 'VALIDATE_PASSWORD'" cols="12">
            <v-text-field
              v-model="properties.fields.password"
              outlined
              dense
              :label="$lang.labels.password"
              required
              :rules="[v => !!v || $lang.labels.required]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-text-field>
          </v-col>
          <v-col v-if="properties.action === 'GET_USER_DETAILS' || properties.action === 'GET_USER_ROLES'" cols="12">
            <v-text-field
              v-model="properties.fields.userId"
              outlined
              dense
              :label="$lang.labels.userId"
              required
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="properties.fields.targetObject"
              outlined
              dense
              :label="$lang.labels.targetObject"
              required
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="type === 'PLUGIN'" wrap no-gutters>
          <v-col cols="12">
            <div class="d-inline-flex" style="width: 100%">
              <v-autocomplete
                v-model="properties.pluginName"
                outlined
                dense
                :items="plugins"
                :loading="isLoadingPlugins"
                :search-input.sync="searchPlugins"
                clearable
                hide-no-data
                item-text="name"
                item-value="name"
                :label="$lang.labels.plugin"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                :readonly="!canEdit"
                data-cy="plugin-plugins"
              ></v-autocomplete>
              <v-btn
                icon
                light
                color="primary"
                class="ml-1"
                data-cy="plugin-refresh-plugins"
                @click="searchPluginsFunction(searchPlugins)"
              >
                <v-icon
                  dense
                  small
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
              <v-btn
                text
                class="ml-1"
                color="primary"
                :disabled="!properties.pluginObj"
                data-cy="plugin-open-plugin"
                @click="openPlugin()"
              >
                {{ $lang.actions.openPlugin }}
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12">
            <v-select
              v-model="properties.action"
              :items="properties.pluginObj ? properties.pluginObj.actions : []"
              :label="!properties.pluginObj ? $lang.labels.selectPlugin : $lang.labels.action"
              :disabled="!properties.pluginObj"
              outlined
              dense
              required
              data-cy="plugin-action"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-select>
          </v-col>

          <v-col cols="12">
            <div class="d-inline-flex" style="width: 100%">
              <v-autocomplete
                v-model="properties.pluginSettingName"
                outlined
                dense
                :items="settings"
                :loading="isLoadingPluginSetting"
                :search-input.sync="searchPluginSetting"
                clearable
                hide-no-data
                item-text="name"
                item-value="name"
                :label="$lang.labels.processSettingName"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                :readonly="!canEdit"
                data-cy="pluginSettings-pluginSettingName"
              ></v-autocomplete>
              <v-btn
                icon
                light
                color="primary"
                class="ml-1"
                @click="searchPluginSettings(searchPluginSetting)"
              >
                <v-icon
                  dense
                  small
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12">
            <div class="d-inline-flex max-width">
              <v-autocomplete
                v-model="properties.pluginCredentialName"
                outlined
                dense
                :items="credentials"
                :loading="isLoadingCredentials"
                :search-input.sync="searchCredentials"
                clearable
                hide-no-data
                item-text="name"
                item-value="name"
                :label="$lang.labels.credential"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                :readonly="!canEdit"
              ></v-autocomplete>
              <v-btn
                icon
                light
                color="primary"
                class="ml-1"
                @click="searchCredentialsFunction(searchCredentials)"
              >
                <v-icon
                  dense
                  small
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12" class="pb-3">
            <add-key-value
              type="input"
              :can-edit="canEdit"
              :step-type="type"
              :data="properties.input"
              :single-step="step"
              @dataChanged="properties.input = $event"
            ></add-key-value>
          </v-col>

          <v-col cols="12" class="pb-3">
            <add-key-value
              type="output"
              :can-edit="canEdit"
              :step-type="type"
              :data="properties.output"
              :single-step="step"
              @dataChanged="properties.output = $event"
            ></add-key-value>
          </v-col>

        </v-row>

        <v-row v-if="type === 'S3'" wrap no-gutters>
          <v-col cols="12">
            <div class="d-inline-flex" style="width: 100%">
              <v-autocomplete
                v-model="properties.credentialName"
                data-cy="s3-credentialname"
                outlined
                dense
                :items="credentials"
                :loading="isLoadingCredentials"
                :search-input.sync="searchCredentials"
                clearable
                hide-no-data
                item-text="name"
                item-value="name"
                :label="$lang.labels.credential"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                :readonly="!canEdit"
                class="required-asterisk"
              ></v-autocomplete>
              <v-btn
                icon
                light
                color="primary"
                class="ml-1"
                @click="searchCredentialsFunction(searchCredentials)"
              >
                <v-icon
                  dense
                  small
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
              <v-btn
                text
                class="ml-1"
                color="primary"
                :disabled="!properties.credentialName"
                @click="openCredential()"
              >
                {{ $lang.actions.openTemplate }}
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12">
            <v-select
              v-model="properties.action"
              :items="formattedS3Actions"
              :label="$lang.labels.action"
              outlined
              dense
              required
              data-cy="s3-action"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-select>
          </v-col>

          <v-col v-if="['READ', 'LIST'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.fields.targetObject"
              outlined
              dense
              :label="$lang.labels.targetObject"
              required
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-text-field>
          </v-col>

          <v-col v-if="['CREATE', 'DELETE'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.fields.targetObject"
              outlined
              dense
              :label="$lang.labels.targetObject"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col v-if="['READ', 'DELETE', 'CREATE'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.fields.key"
              outlined
              dense
              :label="$lang.labels.key"
              required
              :rules="[v => !!v || $lang.labels.required]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-text-field>
          </v-col>

          <v-col v-if="['CREATE'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.fields.content"
              outlined
              dense
              :label="$lang.labels.content"
              required
              :rules="[v => !!v || $lang.labels.required]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="properties.fields.path"
              outlined
              dense
              :label="$lang.labels.path"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

        </v-row>

        <v-row v-if="type === 'UNSET_VARIABLES'" wrap no-gutters>

          <v-col cols="12" class="pb-3">
            <add-keys
              type="variables"
              :can-edit="canEdit"
              :data="properties.variables"
              @dataChanged="properties.variables = $event"
            ></add-keys>
          </v-col>

        </v-row>

        <v-row v-if="type === 'SECURITY'" wrap no-gutters>
          <v-col cols="12">
            <div class="d-inline-flex" style="width: 100%">
              <v-autocomplete
                v-model="properties.credentialName"
                data-cy="security-credentialname"
                outlined
                dense
                :items="credentials"
                :loading="isLoadingCredentials"
                :search-input.sync="searchCredentials"
                clearable
                hide-no-data
                item-text="name"
                item-value="name"
                :label="$lang.labels.credential"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                :readonly="!canEdit"
              ></v-autocomplete>
              <v-btn
                icon
                light
                color="primary"
                class="ml-1"
                @click="searchCredentialsFunction(searchCredentials)"
              >
                <v-icon
                  dense
                  small
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
              <v-btn
                text
                class="ml-1"
                color="primary"
                :disabled="!properties.credentialName"
                @click="openCredential()"
              >
                {{ $lang.actions.openTemplate }}
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12">
            <v-select
              v-model="properties.action"
              :items="formattedSecurityActions"
              :label="$lang.labels.action"
              outlined
              dense
              required
              data-cy="security-action"
              :readonly="!canEdit"
              class="required-asterisk"
              @change="properties.action === 'DECRYPT' ? properties.method = 'AES' : ''"
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-select
              v-model="properties.method"
              :items="properties.action === 'ENCRYPT' ? ['AES', 'HMAC_SHA256'] : ['AES']"
              :label="$lang.labels.method"
              outlined
              dense
              required
              data-cy="security-method"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-select>
          </v-col>

          <v-col cols="12" class="pb-3">
            <add-key-value
              type="variables"
              :can-edit="canEdit"
              :step-type="type"
              :data="properties.variables"
              :single-step="step"
              @dataChanged="properties.variables = $event"
            ></add-key-value>
          </v-col>

        </v-row>

        <v-row v-if="type === 'IMAP'" wrap no-gutters>
          <v-col cols="12">
            <div class="d-inline-flex" style="width: 100%">
              <v-autocomplete
                v-model="properties.credentialName"
                data-cy="imap-credentialname"
                outlined
                dense
                :items="credentials"
                :loading="isLoadingCredentials"
                :search-input.sync="searchCredentials"
                clearable
                hide-no-data
                item-text="name"
                item-value="name"
                :label="$lang.labels.credential"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                :readonly="!canEdit"
              ></v-autocomplete>
              <v-btn
                icon
                light
                color="primary"
                class="ml-1"
                @click="searchCredentialsFunction(searchCredentials)"
              >
                <v-icon
                  dense
                  small
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
              <v-btn
                text
                class="ml-1"
                color="primary"
                :disabled="!properties.credentialName"
                @click="openCredential()"
              >
                {{ $lang.actions.openTemplate }}
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12">
            <v-select
              v-model="properties.action"
              :items="formattedImapActions"
              :label="$lang.labels.action"
              outlined
              dense
              required
              data-cy="imap-action"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-select
              v-model="properties.scriptEngine"
              :items="formattedScriptEngines"
              :label="$lang.labels.scriptEngine"
              outlined
              dense
              required
              data-cy="imap-scriptEngine"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-if="properties.action && properties.action !== 'ATTACHMENTS'"
              v-model="properties.folder"
              outlined
              dense
              data-cy="imap-folder"
              :label="$lang.labels.folder"
              :readonly="!canEdit"
              :rules="[(v) => checkRegexFieldRecipient(v) || $lang.errors.wrongSearchParam]"
            ></v-text-field>
          </v-col>

          <v-col v-if="properties.action === 'SEARCH'" cols="12">
            <v-select
              v-model="properties.fields.readType"
              :items="['ALL', 'READ', 'UNREAD']"
              :label="$lang.labels.readType"
              outlined
              dense
              required
              data-cy="imap-readType"
              :readonly="!canEdit"
            ></v-select>
          </v-col>

          <v-col v-if="properties.action === 'SEARCH'" cols="12">
            <v-text-field
              v-model="properties.fields.sender"
              outlined
              dense
              :label="$lang.labels.sender"
              :readonly="!canEdit"
              data-cy="imap-sender"
              :rules="[(v) => checkRegexFieldRecipient(v) || $lang.errors.wrongSearchParam]"
            ></v-text-field>
          </v-col>

          <v-col v-if="properties.action === 'SEARCH'" cols="12">
            <v-text-field
              v-model="properties.fields.recipient"
              outlined
              dense
              :label="$lang.labels.recipient"
              :readonly="!canEdit"
              data-cy="imap-recipient"
              :rules="[(v) => checkRegexFieldRecipient(v) || $lang.errors.wrongSearchParam]"
            ></v-text-field>
          </v-col>

          <v-col v-if="properties.action === 'SEARCH'" cols="12">
            <v-text-field
              v-model="properties.fields.subject"
              outlined
              dense
              :label="$lang.labels.subject"
              :readonly="!canEdit"
              data-cy="imap-subject"
              :rules="[(v) => checkRegexFieldRecipient(v) || $lang.errors.wrongSearchParam]"
            ></v-text-field>
          </v-col>

          <v-col v-if="properties.action === 'SEARCH'" cols="12">
            <v-text-field
              v-model="properties.fields.body"
              outlined
              dense
              :label="$lang.labels.body"
              data-cy="imap-body"
              :readonly="!canEdit"
              :rules="[(v) => checkRegexFieldRecipient(v) || $lang.errors.wrongSearchParam]"
            ></v-text-field>
          </v-col>

          <v-col v-if="properties.action === 'SEARCH'" cols="12">
            <v-text-field
              v-model="properties.fields.maxResults"
              outlined
              dense
              :label="$lang.labels.maxResults"
              :readonly="!canEdit"
              data-cy="imap-maxResults"
              :rules="[(v) => v === '' || !!Number(v) || $lang.errors.wrongSearchParam]"
            ></v-text-field>
          </v-col>

          <v-col v-if="properties.action && properties.action !== 'SEARCH'" cols="12">
            <v-text-field
              v-model="properties.fields.emailId"
              outlined
              dense
              :label="$lang.labels.emailId"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
              data-cy="imap-emailId"
              class="required-asterisk"
            ></v-text-field>
          </v-col>

          <v-col v-if="['SEARCH', 'ATTACHMENTS'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.fields.targetObject"
              outlined
              dense
              data-cy="imap-targetObject"
              :label="$lang.labels.targetObject"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
              class="required-asterisk"
            ></v-text-field>
          </v-col>

        </v-row>

        <v-row v-if="type === 'PDF'" wrap no-gutters>
          <v-col cols="12">
            <div class="d-inline-flex" style="width: 100%">
              <v-autocomplete
                v-model="properties.textTemplate"
                outlined
                dense
                :items="templates"
                :loading="isLoadingTemplates"
                :search-input.sync="searchTemplates"
                clearable
                hide-no-data
                item-text="name"
                item-value="name"
                required
                :rules="[v => !!v || $lang.labels.required]"
                :label="$lang.labels.textTemplate"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                :readonly="!canEdit"
                class="required-asterisk"
                data-cy="pdf-textTemplate"
              ></v-autocomplete>
              <v-btn
                icon
                light
                color="primary"
                class="ml-1"
                @click="searchTextTemplatesFunction(searchTemplates)"
              >
                <v-icon
                  dense
                  small
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
              <v-btn
                text
                class="ml-1"
                color="primary"
                :disabled="!templateId"
                @click="openTemplate()"
              >
                {{ $lang.actions.openTemplate }}
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="properties.targetObject"
              outlined
              dense
              data-cy="pdf-targetObject"
              :label="$lang.labels.targetObject"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
              class="required-asterisk"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-autocomplete
              v-model="properties.locale"
              data-cy="pdf-locale"
              outlined
              dense
              :items="availableLanguages"
              :search-input.sync="searchLocales"
              clearable
              hide-no-data
              item-value="value"
              item-text="text"
              :label="$lang.labels.locale"
              :placeholder="$lang.actions.startTyping"
              :readonly="!canEdit"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" class="pb-3">
            <add-key-value
              type="variables"
              :can-edit="canEdit"
              :step-type="type"
              :data="properties.variables"
              :text-template-name="properties.textTemplate"
              :single-step="step"
              @dataChanged="properties.variables = $event"
            ></add-key-value>
          </v-col>

        </v-row>

        <v-row v-if="type === 'CSV'" wrap no-gutters>

          <v-col cols="12">
            <v-select
              v-model="properties.action"
              :items="formattedCsvActions"
              :label="$lang.labels.action"
              outlined
              dense
              required
              data-cy="csv-action"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="properties.source"
              outlined
              dense
              data-cy="csv-source"
              :label="$lang.labels.source"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
              class="required-asterisk"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="properties.targetObject"
              outlined
              dense
              data-cy="csv-targetObject"
              :label="$lang.labels.targetObject"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
              class="required-asterisk"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-select
              v-model="properties.delimiter"
              :items="delimiters"
              :label="$lang.labels.delimiter"
              outlined
              dense
              required
              data-cy="csv-delimiter"
              :readonly="!canEdit"
              @change="properties.delimiter !== 'CUSTOM' ? properties.customDelimiter = '' : ''"
            ></v-select>
          </v-col>

          <v-col v-if="properties.delimiter === 'CUSTOM'" cols="12">
            <v-text-field
              v-model="properties.customDelimiter"
              outlined
              dense
              data-cy="csv-customDelimiter"
              :label="$lang.labels.customDelimiter"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required]"
              class="required-asterisk"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-select
              v-model="properties.qualifier"
              :items="qualifiers"
              :label="$lang.labels.qualifier"
              outlined
              dense
              required
              data-cy="csv-qualifier"
              :readonly="!canEdit"
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-checkbox
              v-model="properties.includeHeaders"
              :label="$lang.labels.includeHeaders"
              :readonly="!canEdit"
            ></v-checkbox>
          </v-col>

          <v-col cols="12" class="pb-3">
            <add-key-value
              type="filters"
              :can-edit="canEdit"
              :step-type="type"
              :data="properties.filters"
              :single-step="step"
              @dataChanged="properties.filters = $event"
            ></add-key-value>
          </v-col>

        </v-row>

        <v-row v-if="type === 'IMAGE'" wrap no-gutters>

          <v-col cols="12">
            <v-select
              v-model="properties.action"
              :items="formattedImageActions"
              :label="$lang.labels.action"
              outlined
              dense
              required
              data-cy="image-action"
              :readonly="!canEdit"
              class="required-asterisk"
              @change="onImageActionChange()"
            ></v-select>
          </v-col>

          <v-col v-if="['FROM_TEMPLATE'].includes(properties.action)" cols="12">
            <div class="d-inline-flex" style="width: 100%">
              <v-autocomplete
                v-model="properties.fields.textTemplate"
                outlined
                dense
                :items="templates"
                :loading="isLoadingTemplates"
                :search-input.sync="searchTemplates"
                clearable
                hide-no-data
                item-text="name"
                item-value="name"
                required
                :rules="[v => !!v || $lang.labels.required]"
                :label="$lang.labels.textTemplate"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                :readonly="!canEdit"
                class="required-asterisk"
                data-cy="image-textTemplate"
              ></v-autocomplete>
              <v-btn
                icon
                light
                color="primary"
                class="ml-1"
                @click="searchTextTemplatesFunction(searchTemplates)"
              >
                <v-icon
                  dense
                  small
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
              <v-btn
                text
                class="ml-1"
                color="primary"
                :disabled="!templateId"
                @click="openTemplate()"
              >
                {{ $lang.actions.openTemplate }}
              </v-btn>
            </div>
          </v-col>

          <v-col v-if="['FROM_TEMPLATE'].includes(properties.action)" cols="12">
            <v-autocomplete
              v-model="properties.fields.locale"
              data-cy="image-locale"
              outlined
              dense
              :items="availableLanguages"
              :search-input.sync="searchLocales"
              clearable
              hide-no-data
              item-value="value"
              item-text="text"
              :label="$lang.labels.locale"
              :placeholder="$lang.actions.startTyping"
              :readonly="!canEdit"
            ></v-autocomplete>
          </v-col>

          <v-col v-if="['CONVERT', 'RESCALE', 'DOWNLOAD'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.fields.source"
              outlined
              dense
              data-cy="image-source"
              :label="$lang.labels.source"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
              class="required-asterisk"
            ></v-text-field>
          </v-col>

          <v-col v-if="['CONVERT', 'FROM_TEMPLATE'].includes(properties.action)" cols="12">
            <v-select
              v-model="properties.fields.type"
              :items="imageFormats"
              :label="$lang.labels.type"
              outlined
              dense
              required
              data-cy="image-format"
              :readonly="!canEdit"
              :class="{ 'required-asterisk': ['CONVERT', 'RESCALE'].includes(properties.action) }"
            ></v-select>
          </v-col>

          <v-col v-if="['RESCALE', 'FROM_TEMPLATE'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.fields.width"
              outlined
              dense
              data-cy="image-width"
              :label="$lang.labels.width"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required]"
              class="required-asterisk"
            ></v-text-field>
          </v-col>

          <v-col v-if="['RESCALE', 'FROM_TEMPLATE'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.fields.height"
              outlined
              dense
              data-cy="image-height"
              :label="$lang.labels.height"
              :readonly="!canEdit"
              :class="{ 'required-asterisk': ['RESCALE'].includes(properties.action) }"
            ></v-text-field>
          </v-col>

          <v-col v-if="['FROM_TEMPLATE'].includes(properties.action)" cols="12" class="pb-2">
            <add-key-value
              :key="codeKey"
              type="variables"
              :step-type="type"
              :can-edit="canEdit"
              :data="properties.fields.variables"
              :single-step="step"
              @editor="openEditor($event, 'fields.variables')"
              @dataChanged="properties.fields.variables = $event"
            ></add-key-value>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="properties.targetObject"
              outlined
              dense
              data-cy="image-targetObject"
              :label="$lang.labels.targetObject"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
              class="required-asterisk"
            ></v-text-field>
          </v-col>

        </v-row>

        <v-row v-if="type === 'EXECUTE_EXTERNAL_COMMAND'" wrap no-gutters>

          <v-col cols="12">
            <v-select
              v-model="properties.scriptEngine"
              :items="formattedScriptEngines"
              :label="$lang.labels.scriptEngine"
              outlined
              dense
              required
              :rules="[v => !!v || $lang.labels.required]"
              class="required-asterisk"
              data-cy="eec-scriptEngine"
              :readonly="!canEdit"
            ></v-select>
          </v-col>

          <v-col cols="12" style="position: relative">
            <v-text-field
              v-model="properties.input"
              outlined
              dense
              data-cy="eec-input"
              :label="$lang.labels.input"
              :readonly="!canEdit"
            ></v-text-field>
            <v-btn
              class="button-help"
              style="position: absolute; top: 6px; right: 6px"
              icon
              small
              :disabled="!canEdit"
              @click="selectedItem = { text: '', value: properties.input, parentType: 'input' }; showEditor = true"
            >
              <v-icon small color="info">mdi-pencil</v-icon>
            </v-btn>
          </v-col>

          <v-col cols="12" style="position: relative">
            <v-text-field
              v-model="properties.command"
              outlined
              dense
              data-cy="eec-command"
              :label="$lang.labels.command"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required]"
              class="required-asterisk"
            ></v-text-field>
            <v-btn
              class="button-help"
              style="position: absolute; top: 6px; right: 6px"
              icon
              small
              :disabled="!canEdit"
              @click="selectedItem = { text: '', value: properties.command, parentType: 'command' }; showEditor = true"
            >
              <v-icon small color="info">mdi-pencil</v-icon>
            </v-btn>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="properties.resultCode"
              outlined
              dense
              data-cy="eec-resultCode"
              :label="$lang.labels.resultCode"
              :readonly="!canEdit"
              :rules="[(v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="properties.resultErrors"
              outlined
              dense
              data-cy="eec-resultErrors"
              :label="$lang.labels.resultErrors"
              :readonly="!canEdit"
              :rules="[(v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="properties.targetObject"
              outlined
              dense
              data-cy="eec-targetObject"
              :label="$lang.labels.targetObject"
              :readonly="!canEdit"
              :rules="[(v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
            ></v-text-field>
          </v-col>

        </v-row>

        <v-row v-if="type === 'TRY_CATCH'" wrap no-gutters>
        </v-row>

        <v-row v-if="subType === 'EXCEPTION'" wrap no-gutters>
          <v-col cols="12" class="pt-1" style="position: relative">
            <v-combobox
              v-model="properties.exceptions"
              :items="filteredExceptions[step.localId]"
              outlined
              dense
              required
              multiple
              chips
              deletable-chips
              :readonly="!canEdit"
              :rules="[v => (v && v.length > 0) || 'Required!']"
            />
          </v-col>
          <v-col cols="12" class="pt-1">
            <v-text-field
              v-model="properties.name"
              outlined
              dense
              :label="$lang.labels.name"
              :readonly="!canEdit"
              data-cy="exception-name"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="subType === 'TRY'" no-gutters>
          <v-col cols="12" class="pt-1">
            <v-text-field
              v-model="properties.name"
              outlined
              dense
              :label="$lang.labels.name"
              :readonly="!canEdit"
              data-cy="try-name"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="subType === 'FINALLY'" wrap no-gutters>
          <v-col cols="12" class="pt-1">
            <v-text-field
              v-model="properties.name"
              outlined
              dense
              :label="$lang.labels.name"
              :readonly="!canEdit"
              data-cy="finally-name"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="type === 'MESSAGING'" wrap no-gutters>

          <v-col cols="12">
            <v-select
              v-model="properties.service"
              :items="formattedServices"
              :label="$lang.labels.service"
              outlined
              dense
              required
              :rules="[v => !!v || $lang.labels.required]"
              class="required-asterisk"
              data-cy="messaging-service"
              :readonly="!canEdit"
              @change="onChangeMessageService()"
            ></v-select>
          </v-col>

          <v-col cols="12">
            <div class="d-inline-flex" style="width: 100%">
              <v-autocomplete
                v-model="properties.credentialName"
                data-cy="messaging-credentialname"
                outlined
                dense
                :items="credentials"
                :loading="isLoadingCredentials"
                :search-input.sync="searchCredentials"
                clearable
                hide-no-data
                item-text="name"
                item-value="name"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :label="$lang.labels.credential"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                :readonly="!canEdit"
              ></v-autocomplete>
              <v-btn
                icon
                light
                color="primary"
                class="ml-1"
                @click="searchCredentialsFunction(searchCredentials)"
              >
                <v-icon
                  dense
                  small
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
              <v-btn
                text
                class="ml-1"
                color="primary"
                :disabled="!properties.credentialName"
                @click="openCredential()"
              >
                {{ $lang.actions.openTemplate }}
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12">
            <v-select
              v-model="properties.scriptEngine"
              :items="formattedScriptEngines"
              :label="$lang.labels.scriptEngine"
              outlined
              dense
              data-cy="messaging-scriptEngine"
              :readonly="!canEdit"
            ></v-select>
          </v-col>

          <v-col v-if="properties.service === 'KAFKA'" cols="12">
            <v-text-field
              v-model="properties.config.messageKey"
              outlined
              dense
              :rules="[v => !!v || $lang.labels.required]"
              class="required-asterisk"
              :label="$lang.labels.messageKey"
              :readonly="!canEdit"
              data-cy="messaging-messageKey"
            ></v-text-field>
          </v-col>

          <v-col v-if="['JMS', 'RABBITMQ'].includes(properties.service)" cols="12">
            <v-select
              v-model="properties.config.deliveryMode"
              :items="deliveryModes"
              :label="$lang.labels.deliveryMode"
              outlined
              dense
              data-cy="messaging-deliveryMode"
              :readonly="!canEdit"
            />
          </v-col>

          <v-col v-if="['JMS', 'RABBITMQ'].includes(properties.service)" cols="12">
            <v-text-field
              v-model="properties.config.priority"
              outlined
              dense
              :label="$lang.labels.priority"
              :readonly="!canEdit"
              data-cy="messaging-priority"
            ></v-text-field>
          </v-col>

          <v-col v-if="['JMS', 'RABBITMQ'].includes(properties.service)" cols="12">
            <v-text-field
              v-model="properties.config.timeToLive"
              outlined
              dense
              :label="$lang.labels.timeToLive"
              :readonly="!canEdit"
              data-cy="messaging-timeToLive"
            ></v-text-field>
          </v-col>

          <v-col v-if="['MQTT'].includes(properties.service)" cols="12">
            <v-text-field
              v-model="properties.config.qos"
              outlined
              dense
              :label="$lang.labels.qos"
              :readonly="!canEdit"
              data-cy="messaging-qos"
            ></v-text-field>
          </v-col>

          <v-col v-if="['MQTT'].includes(properties.service)" cols="12">
            <v-checkbox
              v-model="properties.config.retained"
              dense
              data-cy="messaging-retained"
              :label="$lang.labels.retained"
            />
          </v-col>

          <v-col cols="12" style="position: relative">
            <v-textarea
              v-model="properties.message"
              data-cy="messaging-message"
              rows="2"
              outlined
              dense
              :label="$lang.labels.message"
              required
              :rules="[v => !!v || $lang.labels.required]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-textarea>
            <v-btn
              class="button-help"
              style="position: absolute; top: 10px; right: 10px"
              icon
              small
              :disabled="!canEdit"
              @click="selectedItem = { text: '', value: properties.message, parentType: 'message' }; showEditor = true"
            >
              <v-icon small color="info">mdi-pencil</v-icon>
            </v-btn>
          </v-col>

        </v-row>

        <v-row v-if="type === 'PAYMENT_SENSE_PAC'" wrap no-gutters>

          <v-col cols="12">
            <div class="d-inline-flex" style="width: 100%">
              <v-combobox
                v-model="properties.credentialName"
                data-cy="paymentSensePac-credentialname"
                outlined
                dense
                :items="credentials"
                :loading="isLoadingCredentials"
                :search-input.sync="searchCredentials"
                clearable
                hide-no-data
                item-text="name"
                item-value="name"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :label="$lang.labels.credential"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                :readonly="!canEdit"
                @input="isJavaVariable(returnNameOrString(properties.credentialName)) ? '' : credentials.map((x) => x.name).includes(returnNameOrString(properties.credentialName)) ? '' : properties.credentialName = ''"
              ></v-combobox>
              <v-btn
                icon
                light
                color="primary"
                class="ml-1"
                @click="searchCredentialsFunction(searchCredentials)"
              >
                <v-icon
                  dense
                  small
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
              <v-btn
                text
                class="ml-1"
                color="primary"
                :disabled="!properties.credentialName"
                @click="openCredential()"
              >
                {{ $lang.actions.openTemplate }}
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12">
            <v-select
              v-model="properties.action"
              :items="formattedPaymentSensePacActions"
              :label="$lang.labels.action"
              outlined
              dense
              required
              data-cy="paymentSensePac-action"
              :readonly="!canEdit"
              class="required-asterisk"
              @change="paymentSensePacActionChanged(properties.action)"
            ></v-select>
          </v-col>

          <v-col v-if="['START_REPORT'].includes(properties.action)" cols="12">
            <v-select
              v-model="properties.parameters.reportType"
              :items="formattedPaymentSensePacReportTypes"
              :label="$lang.labels.reportType"
              outlined
              dense
              required
              data-cy="paymentSensePac-parameters-reportType"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required]"
              class="required-asterisk"
            ></v-select>
          </v-col>

          <v-col v-if="['SIGNATURE'].includes(properties.action)" cols="12">
            <v-checkbox
              v-model="properties.parameters.accepted"
              dense
              data-cy="paymentSensePac-parameters-accepted"
              :label="$lang.labels.accepted"
            />
          </v-col>

          <v-col v-if="!['START_REPORT', 'GET_TERMINALS', 'GET_TERMINAL'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.requestId"
              outlined
              dense
              data-cy="paymentSensePac-parameters-requestId"
              :label="$lang.labels.requestId"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required]"
              class="required-asterisk"
            ></v-text-field>
          </v-col>

          <v-col v-if="!['GET_TERMINALS'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.terminalId"
              outlined
              dense
              data-cy="paymentSensePac-parameters-terminalId"
              :label="$lang.labels.terminalId"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required]"
              class="required-asterisk"
            ></v-text-field>
          </v-col>

          <v-col v-if="['START_TRANSACTION'].includes(properties.action)" cols="12">
            <v-row no-gutters class="pl-4">
              <h4>{{ $lang.labels.transaction }}</h4>
              <v-col cols="12">
                <v-text-field
                  v-model="properties.parameters.transaction.amount"
                  outlined
                  dense
                  data-cy="paymentSensePac-parameters-transaction-amount"
                  :label="$lang.labels.amount"
                  :readonly="!canEdit"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="properties.parameters.transaction.amountCashback"
                  outlined
                  dense
                  data-cy="paymentSensePac-parameters-transaction-amountCashback"
                  :label="$lang.labels.amountCashback"
                  :readonly="!canEdit"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="properties.parameters.transaction.amountPreAuthorisedTotal"
                  outlined
                  dense
                  data-cy="paymentSensePac-parameters-transaction-amountPreAuthorisedTotal"
                  :label="$lang.labels.amountPreAuthorisedTotal"
                  :readonly="!canEdit"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="properties.parameters.transaction.authCode"
                  outlined
                  dense
                  data-cy="paymentSensePac-parameters-transaction-authCode"
                  :label="$lang.labels.authCode"
                  :readonly="!canEdit"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  v-model="properties.parameters.transaction.cardholderNotPresent"
                  dense
                  data-cy="paymentSensePac-parameters-transaction-cardholderNotPresent"
                  :label="$lang.labels.cardholderNotPresent"
                />
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="properties.parameters.transaction.currency"
                  :items="['EUR', 'GBP']"
                  :label="$lang.labels.currency"
                  outlined
                  dense
                  required
                  data-cy="paymentSensePac-parameters-transaction-currency"
                  :readonly="!canEdit"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="properties.parameters.transaction.transactionType"
                  :items="formattedPaymentSensePacTransactionTypes"
                  :label="$lang.labels.transactionType"
                  outlined
                  dense
                  required
                  data-cy="paymentSensePac-parameters-transaction-transactionType"
                  :readonly="!canEdit"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="properties.targetObject"
              outlined
              dense
              class="required-asterisk"
              data-cy="paymentSensePac-targetObject"
              :label="$lang.labels.targetObject"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
            ></v-text-field>
          </v-col>

        </v-row>

        <v-row v-if="type === 'PAYMENT_SENSE_CONNECT_E'" wrap no-gutters>

          <v-col cols="12">
            <div class="d-inline-flex" style="width: 100%">
              <v-combobox
                v-model="properties.credentialName"
                data-cy="paymentSenseConnectE-credentialname"
                outlined
                dense
                :items="credentials"
                :loading="isLoadingCredentials"
                :search-input.sync="searchCredentials"
                clearable
                hide-no-data
                item-text="name"
                item-value="name"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :label="$lang.labels.credential"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                :readonly="!canEdit"
                @input="isJavaVariable(returnNameOrString(properties.credentialName)) ? '' : credentials.map((x) => x.name).includes(returnNameOrString(properties.credentialName)) ? '' : properties.credentialName = ''"
              ></v-combobox>
              <v-btn
                icon
                light
                color="primary"
                class="ml-1"
                @click="searchCredentialsFunction(searchCredentials)"
              >
                <v-icon
                  dense
                  small
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
              <v-btn
                text
                class="ml-1"
                color="primary"
                :disabled="!properties.credentialName"
                @click="openCredential()"
              >
                {{ $lang.actions.openTemplate }}
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12">
            <v-select
              v-model="properties.action"
              :items="formattedPaymentSenseConnectEActions"
              :label="$lang.labels.action"
              outlined
              dense
              required
              data-cy="paymentSenseConnectE-action"
              :readonly="!canEdit"
              class="required-asterisk"
              @change="paymentSenseConnectEActionChanged(properties.action)"
            ></v-select>
          </v-col>

          <v-col v-if="['CROSS_REFERENCE_PAYMENT', 'PAYMENT_DETAILS', 'RESUME_PAYMENT', 'REVOKE_ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.accessToken"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-accessToken"
              :label="$lang.labels.accessToken"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required]"
              class="required-asterisk"
            ></v-text-field>
          </v-col>

          <v-col v-if="['CROSS_REFERENCE_PAYMENT'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.crossReferencePayment.crossReference"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-crossReference"
              :label="$lang.labels.crossReference"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required]"
              class="required-asterisk"
            ></v-text-field>
          </v-col>

          <v-col v-if="['CROSS_REFERENCE_PAYMENT'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.crossReferencePayment.cv2"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-cv2"
              :label="$lang.labels.cv2"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.amount"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-amount"
              :label="$lang.labels.amount"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.crossReference"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-crossReference"
              :label="$lang.labels.crossReference"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.currencyCode"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-currencyCode"
              :label="$lang.labels.currencyCode"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required]"
              class="required-asterisk"
            ></v-text-field>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.customerId"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-customerId"
              :label="$lang.labels.customerId"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.merchantTransactionId"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-merchantTransactionId"
              :label="$lang.labels.merchantTransactionId"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.merchantUrl"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-merchantUrl"
              :label="$lang.labels.merchantUrl"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required]"
              class="required-asterisk"
            ></v-text-field>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12" class="pt-1 pb-2">
            <add-key-value
              :key="codeKey"
              :step-type="type"
              type="metaData"
              :can-edit="canEdit"
              :data="properties.parameters.metaData"
              :single-step="step"
              @editor="openEditor($event, 'parameters.metaData', type)"
              @dataChanged="properties.parameters.metaData = $event"
            ></add-key-value>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12" class="py-1">
            <v-checkbox
              v-model="properties.parameters.newTransaction"
              dense
              data-cy="paymentSensePac-parameters-newTransaction"
              :label="$lang.labels.newTransaction"
            />
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.orderDescription"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-orderDescription"
              :label="$lang.labels.orderDescription"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.orderId"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-orderId"
              :label="$lang.labels.orderId"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required]"
              class="required-asterisk"
            ></v-text-field>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <p>{{ $lang.labels.shippingDetails }}</p>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.shippingDetails.name"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-name"
              :label="$lang.labels.name"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <p>{{ $lang.labels.address }}</p>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.shippingDetails.address.userAddress1"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-address-userAddress2"
              :label="$lang.labels.userAddress1"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.shippingDetails.address.userAddress2"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-address-userAddress2"
              :label="$lang.labels.userAddress2"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.shippingDetails.address.userAddress3"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-address-userAddress3"
              :label="$lang.labels.userAddress3"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.shippingDetails.address.userAddress4"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-address-userAddress4"
              :label="$lang.labels.userAddress4"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.shippingDetails.address.userCity"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-address-userCity"
              :label="$lang.labels.userCity"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.shippingDetails.address.userCountryCode"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-address-userCountryCode"
              :label="$lang.labels.userCountryCode"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.shippingDetails.address.userPostcode"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-address-userPostcode"
              :label="$lang.labels.userPostcode"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.shippingDetails.address.userState"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-address-userState"
              :label="$lang.labels.userState"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12" class="mb-2">
            <v-divider />
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-select
              v-model="properties.parameters.transactionSource"
              :items="['MOTO']"
              :label="$lang.labels.transactionSource"
              outlined
              dense
              required
              data-cy="paymentSensePac-parameters-transactionSource"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required]"
              class="required-asterisk"
            ></v-select>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-select
              v-model="properties.parameters.transactionType"
              :items="[ 'COLLECTION', 'PREAUTH', 'REFUND', 'SALE', 'VOID' ]"
              :label="$lang.labels.transactionType"
              outlined
              dense
              required
              data-cy="paymentSensePac-parameters-transactionType"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required]"
              class="required-asterisk"
            ></v-select>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.userAddress1"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-userAddress1"
              :label="$lang.labels.userAddress1"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.userAddress2"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-userAddress2"
              :label="$lang.labels.userAddress2"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.userAddress3"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-userAddress3"
              :label="$lang.labels.userAddress3"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.userAddress4"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-userAddress4"
              :label="$lang.labels.userAddress4"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.userAgent"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-userAgent"
              :label="$lang.labels.userAgent"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.userCity"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-userCity"
              :label="$lang.labels.userCity"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.userCountryCode"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-userCountryCode"
              :label="$lang.labels.userCountryCode"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.userEmailAddress"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-userEmailAddress"
              :label="$lang.labels.userEmailAddress"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.userIpAddress"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-userIpAddress"
              :label="$lang.labels.userIpAddress"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.userPhoneNumber"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-userPhoneNumber"
              :label="$lang.labels.userPhoneNumber"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.userPostcode"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-userPostcode"
              :label="$lang.labels.userPostcode"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.userState"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-userState"
              :label="$lang.labels.userState"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-checkbox
              v-model="properties.parameters.waitPreExecute"
              dense
              data-cy="paymentSensePac-parameters-waitPreExecute"
              :label="$lang.labels.waitPreExecute"
            />
          </v-col>

          <v-col v-if="['ACCESS_TOKEN'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.parameters.webHookUrl"
              outlined
              dense
              data-cy="paymentSenseConnectE-parameters-webHookUrl"
              :label="$lang.labels.webHookUrl"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="properties.targetObject"
              outlined
              dense
              class="required-asterisk"
              data-cy="paymentSenseConnectE-targetObject"
              :label="$lang.labels.targetObject"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
            ></v-text-field>
          </v-col>

        </v-row>

        <v-row v-if="type === 'UUID'" wrap no-gutters>

          <v-col cols="12">
            <v-text-field
              v-model="properties.targetObject"
              outlined
              dense
              class="required-asterisk"
              data-cy="uuid-targetObject"
              :label="$lang.labels.targetObject"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
            ></v-text-field>
          </v-col>

        </v-row>

        <v-row v-if="type === 'CREDENTIAL'" wrap no-gutters>

          <v-col cols="12">
            <v-select
              v-model="properties.action"
              :items="formattedCredentialActions"
              :label="$lang.labels.action"
              outlined
              dense
              required
              data-cy="credential-action"
              :readonly="!canEdit"
              class="required-asterisk"
              @change="credentialActionChanged(properties.action)"
            ></v-select>
          </v-col>

          <v-col v-if="['ENABLE', 'DISABLE', 'UPDATE'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.fields.queryName"
              outlined
              dense
              class="required-asterisk"
              data-cy="credential-queryName"
              :label="$lang.labels.queryName"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required]"
            ></v-text-field>
          </v-col>

          <v-col v-if="['CREATE', 'UPDATE'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.fields.name"
              outlined
              dense
              class="required-asterisk"
              data-cy="credential-name"
              :label="$lang.labels.name"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required]"
            ></v-text-field>
          </v-col>

          <v-col v-if="['CREATE', 'UPDATE'].includes(properties.action)" cols="12">
            <v-select
              v-model="properties.fields.type"
              :items="formattedCredentialFieldsTypes"
              :label="$lang.labels.type"
              outlined
              dense
              required
              data-cy="credential-type"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required]"
              class="required-asterisk"
            ></v-select>
          </v-col>

          <v-col v-if="['CREATE', 'UPDATE'].includes(properties.action)" cols="12" class="mb-4">
            <roles-list
              :label="$lang.labels.roles"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required]"
              class="required-asterisk"
              data-cy="credential-roles"
              :can-edit="canEdit"
              :data="properties.fields.roles"
              @dataChanged="properties.fields.roles = $event"
            />
          </v-col>

          <v-col v-if="['CREATE'].includes(properties.action)" cols="12">
            <v-text-field
              v-model="properties.fields.targetObject"
              outlined
              dense
              class="required-asterisk"
              data-cy="credential-targetObject"
              :label="$lang.labels.targetObject"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
            ></v-text-field>
          </v-col>

          <v-col v-if="['CREATE', 'UPDATE'].includes(properties.action)" cols="12" class="pt-1 pb-2">
            <add-key-value
              :key="codeKey"
              :step-type="type"
              type="values"
              :can-edit="canEdit"
              :data="properties.fields.values"
              :single-step="step"
              @editor="openEditor($event, 'fields.values', type)"
              @dataChanged="properties.fields.values = $event"
            ></add-key-value>
          </v-col>

        </v-row>

        <v-row v-if="type === 'QUERY_BUILDER'" wrap no-gutters>

          <v-col cols="12">
            <v-select
              v-model="properties.scriptEngine"
              :items="formattedScriptEngines"
              :label="$lang.labels.scriptEngine"
              outlined
              dense
              data-cy="queryBuilder-scriptEngine"
              :readonly="!canEdit"
            ></v-select>
          </v-col>

          <v-col cols="12" class="pt-1 pb-2">
            <add-query-conditions
              :key="codeKey"
              :step-type="type"
              type="queryConditions"
              :can-edit="canEdit"
              :vars="steps"
              :step="step"
              :data="properties.queryConditions"
              @dataChanged="properties.queryConditions = $event"
            ></add-query-conditions>
          </v-col>

          <v-col cols="12" class="pt-2">
            <v-combobox
              v-model="properties.delimiter"
              data-cy="queryBuilder-delimiter"
              outlined
              dense
              :items="queryDelimiters"
              clearable
              hide-no-data
              :rules="[v => v ? v.length < 17 || 'Max 16 characters' : true]"
              :label="$lang.labels.delimiter"
              :readonly="!canEdit"
            ></v-combobox>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="properties.targetObject"
              outlined
              dense
              class="required-asterisk"
              data-cy="queryBuilder-targetObject"
              :label="$lang.labels.targetObject"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
            ></v-text-field>
          </v-col>

        </v-row>

        <v-row v-if="type === 'ZIP'" wrap no-gutters>

          <v-col cols="12">
            <v-select
              v-model="properties.action"
              :items="formattedZipActions"
              :label="$lang.labels.action"
              outlined
              dense
              required
              data-cy="zip-action"
              :readonly="!canEdit"
              class="required-asterisk"
              @change="onChangeZipAction()"
            ></v-select>
          </v-col>

          <v-col v-if="properties.action === 'COMPRESS'" cols="12" class="pt-1 pb-2">
            <zip-entries
              :key="codeKey"
              :can-edit="canEdit"
              :data="properties.fields.entries"
              @dataChanged="properties.fields.entries = $event"
            ></zip-entries>
          </v-col>

          <v-col v-if="properties.action === 'DECOMPRESS'" cols="12">
            <v-text-field
              v-model="properties.fields.sourceObject"
              outlined
              dense
              class="required-asterisk"
              data-cy="zip-sourceObject"
              :label="$lang.labels.sourceObject"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="properties.targetObject"
              outlined
              dense
              class="required-asterisk"
              data-cy="zip-targetObject"
              :label="$lang.labels.targetObject"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
            ></v-text-field>
          </v-col>

        </v-row>

        <v-row v-if="type === 'LOG'" wrap no-gutters>

          <v-col cols="12">
            <v-text-field
              v-model="properties.message"
              outlined
              dense
              data-cy="log-message"
              class="required-asterisk"
              :rules="[v => !!v || $lang.labels.required]"
              :label="$lang.labels.message"
              :readonly="!canEdit"
            />
          </v-col>

        </v-row>

        <v-row v-if="type === 'ENCODER'" wrap no-gutters>

          <v-col cols="12">
            <v-select
              v-model="properties.action"
              :items="formattedEncoderActions"
              :label="$lang.labels.action"
              outlined
              dense
              required
              data-cy="encoder-action"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-select>
          </v-col>

          <v-col
            v-if="properties.action === 'CHANGE_ENCODING'"
            cols="12"
          >
            <v-autocomplete
              v-model="properties.sourceEncoding"
              :items="encodingTypes"
              :label="$lang.labels.sourceEncoding"
              outlined
              dense
              required
              :rules="[v => !!v || $lang.labels.required]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-autocomplete>
          </v-col>

          <v-col
            v-if="properties.action === 'CHANGE_ENCODING'"
            cols="12"
          >
            <v-autocomplete
              v-model="properties.targetEncoding"
              :items="encodingTypes"
              :label="$lang.labels.targetEncoding"
              outlined
              dense
              required
              :rules="[v => !!v || $lang.labels.required]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" class="pt-1 pb-2">
            <encoder-step-converts
              :key="codeKey"
              :step-type="type"
              type="converts"
              :can-edit="canEdit"
              :data="properties.converts"
              @dataChanged="properties.converts = $event"
            ></encoder-step-converts>
          </v-col>

        </v-row>

        <v-row v-if="type === 'STORAGE'" wrap no-gutters>

          <v-col cols="12">
            <v-select
              v-model="properties.scriptEngine"
              :items="formattedScriptEngines"
              :label="$lang.labels.scriptEngine"
              outlined
              dense
              data-cy="storage-scriptEngine"
              :readonly="!canEdit"
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-select
              v-model="properties.action"
              :items="formattedStorageActions"
              :label="$lang.labels.action"
              outlined
              dense
              required
              data-cy="storage-action"
              :readonly="!canEdit"
              class="required-asterisk"
              @change="onChangeStorageAction()"
            ></v-select>
          </v-col>

          <v-col cols="12">
            <div class="d-flex">
              <v-text-field
                v-model="properties['key']"
                outlined
                dense
                class="required-asterisk"
                data-cy="storage-key"
                :label="$lang.labels.key"
                :readonly="!canEdit"
                :rules="[v => !!v || $lang.labels.required]"
              ></v-text-field>
              <v-btn
                text
                color="primary"
                :disabled="!canEdit || !$route.params.id || !properties['key']"
                @click="openStorage(properties['key'])"
              >
                {{ $lang.labels.open }}
              </v-btn>
            </div>
          </v-col>

          <v-col v-if="properties.action === 'GET'" cols="12">
            <v-text-field
              v-model="properties.fields.targetObject"
              outlined
              dense
              class="required-asterisk"
              data-cy="storage-targetObject"
              :label="$lang.labels.targetObject"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
            ></v-text-field>
          </v-col>

          <v-col v-if="properties.action === 'UPSERT'" cols="12">
            <v-text-field
              v-model="properties.fields.expiresIn"
              outlined
              dense
              class="required-asterisk"
              data-cy="storage-expiresIn"
              :label="$lang.labels.expiresIn"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required]"
            ></v-text-field>
          </v-col>

          <v-col v-if="properties.action === 'UPSERT'" cols="12">
            <v-text-field
              v-model="properties.fields['value']"
              outlined
              dense
              class="required-asterisk"
              data-cy="storage-value"
              :label="$lang.labels['value']"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required]"
            ></v-text-field>
          </v-col>

        </v-row>

        <v-row v-if="type === 'FORMATTING'" wrap no-gutters>

          <v-col cols="12">
            <v-select
              v-model="properties.action"
              :items="formattedFormattingActions"
              :label="$lang.labels.action"
              outlined
              dense
              required
              data-cy="formatting-action"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-select>
          </v-col>

          <v-col v-if="properties.action === 'DECIMAL_FORMAT'" cols="12">
            <v-autocomplete
              v-model="properties.fields.locale"
              :items="localesFormatting"
              :label="$lang.labels.locale"
              outlined
              dense
              required
              data-cy="formatting-locale"
              :readonly="!canEdit"
              class="required-asterisk"
              :rules="[v => !!v || $lang.labels.required]"
            />
          </v-col>

          <v-col v-if="properties.action === 'DECIMAL_FORMAT'" cols="12">
            <v-text-field
              v-model="properties.fields.pattern"
              outlined
              dense
              class="required-asterisk"
              data-cy="formatting-pattern"
              :label="$lang.labels.pattern"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required]"
            ></v-text-field>
          </v-col>

          <v-col cols="12" class="pt-1 pb-2">
            <encoder-step-converts
              :key="codeKey"
              :step-type="type"
              type="converts"
              :can-edit="canEdit"
              :data="properties.converts"
              @dataChanged="properties.converts = $event"
            ></encoder-step-converts>
          </v-col>

        </v-row>

        <v-row v-if="type === 'PYTHON'" wrap no-gutters>
          <v-col cols="12" class="pb-2">
            <div class="d-inline-flex" style="width: 100%">
              <v-autocomplete
                v-model="properties.credentialName"
                data-cy="python-credentialname"
                outlined
                dense
                :items="credentials"
                :loading="isLoadingCredentials"
                :search-input.sync="searchCredentials"
                clearable
                hide-no-data
                hide-details
                item-text="name"
                item-value="name"
                :label="$lang.labels.credential"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                :readonly="!canEdit"
              ></v-autocomplete>
              <v-btn
                icon
                light
                color="primary"
                class="ml-1"
                @click="searchCredentialsFunction(searchCredentials)"
              >
                <v-icon
                  dense
                  small
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
              <v-btn
                text
                class="ml-1"
                color="primary"
                :disabled="!properties.credentialName"
                @click="openCredential()"
              >
                {{ $lang.actions.openTemplate }}
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12" class="pb-2">
            <v-autocomplete
              v-model="properties.librariesNames"
              data-cy="python-librariesNames"
              outlined
              dense
              :items="librariesFormatted"
              :loading="isLoadingLibraries"
              :search-input.sync="searchLibraries"
              multiple
              chips
              hide-no-data
              hide-details
              item-text="name"
              item-value="name"
              :label="$lang.labels.libraries"
              :placeholder="$lang.actions.startTyping"
              prepend-inner-icon="mdi-cloud-search-outline"
              :readonly="!canEdit"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" class="py-1">
            <ExecutionResultPairPythonModal
              :key="codeKey"
              :step-type="type"
              type="python"
              :can-edit="canEdit"
              :steps="steps"
              :data="properties.set"
              @dataChanged="properties.set = $event"
            />
          </v-col>
        </v-row>

        <v-row v-if="type === 'XSLT'" wrap no-gutters>

          <v-col cols="12">
            <v-text-field
              v-model="properties.xml"
              outlined
              dense
              class="required-asterisk"
              data-cy="xslt-xml"
              :label="$lang.labels.xml"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="properties.xslt"
              outlined
              dense
              class="required-asterisk"
              data-cy="xslt-xslt"
              :label="$lang.labels.xslt"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="properties.targetObject"
              outlined
              dense
              class="required-asterisk"
              data-cy="xslt-targetObject"
              :label="$lang.labels.targetObject"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
            ></v-text-field>
          </v-col>

        </v-row>

        <v-row v-if="type === 'GRAPHQL'" wrap no-gutters>
          <v-col cols="12">
            <v-select
              v-model="properties.scriptEngine"
              :items="formattedScriptEngines"
              :label="$lang.labels.scriptEngine"
              outlined
              dense
              required
              data-cy="graphql-scriptEngine"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <div class="d-inline-flex" style="width: 100%">
              <v-combobox
                v-model="properties.credentialName"
                outlined
                dense
                :items="credentials"
                :loading="isLoadingCredentials"
                :search-input.sync="searchCredentials"
                clearable
                hide-no-data
                item-text="name"
                item-value="name"
                :label="$lang.labels.credential"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                :readonly="!canEdit"
                @input="isJavaVariable(returnNameOrString(properties.credentialName)) ? '' : credentials.map((x) => x.name).includes(returnNameOrString(properties.credentialName)) ? '' : properties.credentialName = ''"
              ></v-combobox>
              <v-btn
                icon
                light
                color="primary"
                class="ml-1"
                @click="searchCredentialsFunction(searchCredentials)"
              >
                <v-icon
                  dense
                  small
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
              <v-btn
                text
                class="ml-1"
                color="primary"
                :disabled="!properties.credentialName"
                @click="openCredential()"
              >
                {{ $lang.actions.openTemplate }}
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" class="py-1">
            <add-key-value
              :key="codeKey + 20000"
              type="variables"
              :step-type="type"
              :can-edit="canEdit"
              :data="properties.variables"
              :single-step="step"
              @editor="openEditor($event, 'variables')"
              @dataChanged="properties.variables = $event"
            ></add-key-value>
          </v-col>
          <v-col cols="12" class="mt-6 mb-1">
            <add-key-value
              :key="codeKey + 30000"
              type="headers"
              :step-type="type"
              :can-edit="canEdit"
              :data="properties.headers"
              :single-step="step"
              @editor="openEditor($event, 'headers')"
              @dataChanged="properties.headers = $event"
            ></add-key-value>
          </v-col>
          <v-col cols="12" class="mt-6" style="position: relative">
            <v-textarea
              v-model="properties.query"
              data-cy="graphql-query"
              rows="6"
              outlined
              dense
              :label="$lang.labels.query"
              required
              :rules="[v => !!v || $lang.labels.required]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-textarea>
            <v-btn
              class="button-help"
              style="position: absolute; top: 10px; right: 10px"
              icon
              small
              :disabled="!canEdit"
              @click="selectedItem = { text: '', value: properties.query, parentType: 'query' }; showEditor = true"
            >
              <v-icon small color="info">mdi-pencil</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" class="pt-1">
            <v-text-field
              v-model="properties.responseCode"
              outlined
              dense
              :label="$lang.labels.responseCode"
              required
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="properties.targetObject"
              outlined
              dense
              :label="$lang.labels.qraphQlResponseContentTargetObject"
              required
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="position-relative">
            <v-text-field
              v-model="properties.targetUrl"
              outlined
              dense
              :label="$lang.labels.targetUrl"
              required
              :rules="[v => !!v || $lang.labels.required]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-text-field>
            <v-btn
              class="button-help"
              style="position: absolute; top: 6px; right: 4px"
              icon
              small
              :disabled="!canEdit"
              @click="selectedItem = { text: '', value: properties.targetUrl, parentType: 'targetUrl' }; showEditor = true"
            >
              <v-icon small color="info">mdi-pencil</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="9">
            <v-text-field
              v-model="tempTimeout"
              outlined
              dense
              :label="$lang.labels.changeableTimeout"
              required
              :rules="[v => !!v || $lang.labels.required]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="timeUnit"
              :items="timeUnits"
              :label="$lang.labels.unit"
              outlined
              dense
              required
              class="pl-2"
              :readonly="!canEdit"
            ></v-select>
          </v-col>
        </v-row>

        <v-row v-if="type === 'ESC_CHARS'" wrap no-gutters>
          <v-col cols="12">
            <v-text-field
              v-model="properties.sourceObject"
              outlined
              dense
              :label="$lang.labels.sourceObject"
              required
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="properties.targetObject"
              outlined
              dense
              :label="$lang.labels.targetObject"
              required
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="type === 'FTP'" wrap no-gutters>
          <v-col cols="12">
            <v-select
              v-model="properties.action"
              :items="formattedFtpActions"
              :label="$lang.labels.action"
              outlined
              dense
              required
              :rules="[v => !!v || $lang.labels.required]"
              data-cy="ftp-action"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-checkbox
              v-model="isSftp"
              :label="$lang.labels.isSftp"
              :readonly="!canEdit"
              class="mt-0 pt-0"
            ></v-checkbox>
          </v-col>

          <v-col cols="12">
            <div class="d-inline-flex" style="width: 100%">
              <v-autocomplete
                v-model="properties.credentialName"
                data-cy="ftp-credentialName"
                outlined
                dense
                :items="credentials"
                :loading="isLoadingCredentials"
                :search-input.sync="searchCredentials"
                clearable
                hide-no-data
                :rules="[v => !!v || $lang.labels.required]"
                item-text="name"
                item-value="name"
                :label="$lang.labels.credential"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                :readonly="!canEdit"
                class="required-asterisk"
              ></v-autocomplete>
              <v-btn
                icon
                light
                color="primary"
                class="ml-1"
                @click="searchCredentialsFunction(searchCredentials)"
              >
                <v-icon
                  dense
                  small
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
              <v-btn
                text
                class="ml-1"
                color="primary"
                :disabled="!properties.credentialName"
                @click="openCredential()"
              >
                {{ $lang.actions.openTemplate }}
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="properties.path"
              outlined
              dense
              :label="$lang.labels.path"
              required
              :rules="[v => !!v || $lang.labels.required]"
              :readonly="!canEdit"
              class="required-asterisk"
              data-cy="ftp-path"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="properties.targetObject"
              outlined
              dense
              :label="$lang.labels.targetObject"
              required
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
              :readonly="!canEdit"
              class="required-asterisk"
              data-cy="ftp-targetObject"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="type === 'XML'" wrap no-gutters>
          <v-col cols="12">
            <v-select
              v-model="properties.action"
              :items="formattedXmlActions"
              :label="$lang.labels.action"
              outlined
              dense
              required
              :rules="[v => !!v || $lang.labels.required]"
              data-cy="xml-action"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="properties.sourceObject"
              outlined
              dense
              :label="$lang.labels.sourceObject"
              required
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
              :readonly="!canEdit"
              class="required-asterisk"
              data-cy="xml-targetObject"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="properties.targetObject"
              outlined
              dense
              :label="$lang.labels.targetObject"
              required
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
              :readonly="!canEdit"
              class="required-asterisk"
              data-cy="xml-targetObject"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="type === 'OCR'" wrap no-gutters>
          <v-col cols="12">
            <v-autocomplete
              v-model="properties.language"
              data-cy="ocr-language"
              outlined
              dense
              :items="formattedOcrLanguages"
              clearable
              hide-no-data
              :label="$lang.labels.language"
              :readonly="!canEdit"
              :rules="[v => !!v || $lang.labels.required]"
              class="required-asterisk"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="properties.image"
              outlined
              dense
              :label="$lang.labels.image"
              required
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
              :readonly="!canEdit"
              class="required-asterisk"
              data-cy="ocr-image"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="properties.targetObject"
              outlined
              dense
              :label="$lang.labels.targetObject"
              required
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
              :readonly="!canEdit"
              class="required-asterisk"
              data-cy="ocr-targetObject"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="type === 'QR'" wrap no-gutters>
          <v-col cols="12">
            <v-text-field
              v-model="properties.content"
              outlined
              dense
              :label="$lang.labels.content"
              required
              :rules="[v => !!v || $lang.labels.required]"
              :readonly="!canEdit"
              class="required-asterisk"
              data-cy="qr-content"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="properties.size"
              outlined
              dense
              :label="$lang.labels.size"
              required
              :rules="[v => !!v || $lang.labels.required]"
              :readonly="!canEdit"
              class="required-asterisk"
              type="number"
              data-cy="qr-size"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="properties.targetObject"
              outlined
              dense
              :label="$lang.labels.targetObject"
              required
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
              :readonly="!canEdit"
              class="required-asterisk"
              data-cy="qr-targetObject"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="type === 'CHAT_GPT'" :key="codeKey" wrap no-gutters>
          <v-col cols="12">
            <v-select
              v-model="properties.scriptEngine"
              :items="formattedScriptEngines"
              :label="$lang.labels.scriptEngine"
              outlined
              dense
              required
              data-cy="chat-gpt-scriptEngine"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-select>
          </v-col>

          <v-col cols="12">
            <div class="d-inline-flex" style="width: 100%">
              <v-autocomplete
                v-model="properties.credentialName"
                data-cy="chat-gpt-credentialName"
                outlined
                dense
                :items="credentials"
                :loading="isLoadingCredentials"
                :search-input.sync="searchCredentials"
                clearable
                hide-no-data
                :rules="[v => !!v || $lang.labels.required]"
                item-text="name"
                item-value="name"
                :label="$lang.labels.credential"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                :readonly="!canEdit"
                class="required-asterisk"
              ></v-autocomplete>
              <v-btn
                icon
                light
                color="primary"
                class="ml-1"
                @click="searchCredentialsFunction(searchCredentials)"
              >
                <v-icon
                  dense
                  small
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
              <v-btn
                text
                class="ml-1"
                color="primary"
                :disabled="!properties.credentialName"
                @click="openCredential()"
              >
                {{ $lang.actions.openTemplate }}
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="properties.model"
              outlined
              dense
              :label="$lang.labels.model"
              required
              :rules="[v => !!v || $lang.labels.required]"
              :readonly="!canEdit"
              class="required-asterisk"
              data-cy="chat-gpt-model"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="properties.targetObject"
              outlined
              dense
              :label="$lang.labels.targetObject"
              required
              :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
              :readonly="!canEdit"
              class="required-asterisk"
              data-cy="chat-gpt-targetObject"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <chat-gpt-messages
              :key="codeKey + 40000"
              :can-edit="canEdit"
              :data="properties.messages"
              @dataChanged="properties.messages = $event"
            />
          </v-col>
        </v-row>

      </v-form>
    </v-col>
    <v-dialog v-if="showEditor" v-model="showEditor" max-width="71%">
      <editor
        :item="selectedItem"
        :language="language"
        :vars="steps"
        :step="step"
        @closeDialog="showEditor = false; selectedItem = null"
        @fromEditor="updateFromEditor($event)"
      ></editor>
    </v-dialog>
    <v-dialog v-if="showGlobalEditor" v-model="showGlobalEditor" max-width="71%">
      <global-editor
        :title="$lang.labels.isBodyScript"
        :item="selectedItem"
        :with-buttons="true"
        @closeDialog="showGlobalEditor = false; selectedItem = null"
        @fromGlobalEditor="updateFromEditor($event)"
      ></global-editor>
    </v-dialog>
    <v-dialog v-if="showSlackEditor" v-model="showSlackEditor" min-width="90%">
      <slack-message-editor
        :item="selectedItem"
        :can-edit="canEdit"
        @closeDialog="showSlackEditor = false; selectedItem = null"
        @fromEditor="updateFromEditor($event)"
      ></slack-message-editor>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  getLibrariesUsingGET as getLibraries,
  getPluginsUsingGET as getPlugins,
  getProcessByIdUsingGET as getProcess,
  getProcessCredentialsUsingGET as getCredentials,
  getProcessesUsingGET as getProcesses,
  getSettingsUsingGET as getSettings,
  getTextTemplatesUsingGET as getTemplates,
  getValidationRuleByIdUsingGET as getValidationRule,
  getEncodingTypesUsingGET as getEncodingTypes,
  getStoragesUsingGET as getStorage
} from '@/utils/api'
import { predictBestTimeUnitFromMsValue, arraysEqual, objectsEqual } from '@/utils/helpers'
import addKeyValue from './KeyValPairModal'
import ExecutionResultPairPythonModal from './ExecutionResultPairPythonModal.vue'
import addQueryConditions from './QueryConditionsPairModal.vue'
import addAttachments from './Attachments'
import addKeys from './KeyList'
import addVals from './ValList'
import zipEntries from './ZipEntriesModal.vue'
import editor from './Editor'
import globalEditor from '../../components/ui/GlobalEditor'
import SlackMessageEditor from './SlackMessageEditor'
import RolesList from './RolesList.vue'
import { definitions } from '@/utils/definitions'
import CustomExternalProcessInputKeyValue from './CustomExternalProcessInputKeyValue'
import EncoderStepConverts from './EncoderStepConverts'
import { localesFormatting } from '@/utils/constants'
import { mapActions } from 'vuex'
import ChatGptMessages from '@/pages/processes/ChatGptMessages.vue'

export default {
  components: {
    CustomExternalProcessInputKeyValue,
    addKeyValue,
    addAttachments,
    editor,
    addKeys,
    globalEditor,
    SlackMessageEditor,
    addVals,
    addQueryConditions,
    zipEntries,
    RolesList,
    EncoderStepConverts,
    ExecutionResultPairPythonModal,
    ChatGptMessages
  },
  props: {
    step: {
      type: Object,
      default: () => {
        return null
      }
    },
    steps: {
      type: Array,
      default: () => []
    },
    isNewSelected: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    canEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    filteredExceptions: {
      type: Object,
      default: () => {
        return {}
      }
    },
    resourceIsSystem: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    allVariables: {},
    language: '',
    searchLocales: '',
    showCc: false,
    showBcc: false,
    showEditor: false,
    showJsGroovyEditor: false,
    selectedItem: null,
    tempKey: 0,
    lock: false,
    valid: false,
    isLoadingCredentials: false,
    credentials: [],
    isLoadingProcesses: false,
    processes: [],
    isLoadingTemplates: false,
    templates: [],
    isLoadingProcessSetting: false,
    isLoadingPlugins: false,
    plugins: [],
    settings: [],
    stepData: {},
    isTemplate: false,
    searchProcesses: '',
    searchCredentials: '',
    searchTemplates: '',
    searchProcessSetting: '',
    searchPlugins: '',
    searchPluginSetting: '',
    isLoadingPluginSetting: false,
    properties: {
      model: '',
      messages: [],
      language: '',
      image: '',
      path: '',
      service: '',
      topic: '',
      config: {
        messageKey: '',
        topic: ''
      },
      exceptions: [],
      try: [],
      catch: [],
      finally: [],
      action: '',
      credentialName: '',
      processSettingName: '',
      query: '',
      targetObject: '',
      recordName: '',
      async: false,
      input: {},
      output: {},
      processName: '',
      set: {},
      conditions: [],
      collectionName: '',
      queryUpdate: '',
      textTemplate: '',
      variables: {
        subject: '',
        to: ''
      },
      body: {},
      headers: {},
      bodyScript: '',
      bodyTemplate: null,
      queryParams: {},
      restResponseCode: '',
      targetUrl: '',
      channelId: '',
      message: '',
      payload: {},
      timeout: '',
      tempTimeout: '',
      from: '',
      to: '',
      scriptEngine: '',
      fields: {
        email: '',
        name: '',
        roles: [],
        targetObject: '',
        apiKey: '',
        password: '',
        path: '',
        key: '',
        content: '',
        body: '',
        maxResults: '',
        readType: '',
        recipient: '',
        sender: '',
        subject: '',
        emailId: '',
        userId: '',
        width: '',
        height: '',
        format: '',
        source: '',
        variables: '',
        locale: '',
        textTemplate: '',
        type: '',
        values: {},
        id: '',
        queryName: '',
        sourceObject: '',
        expiresIn: '',
        value: '',
        pattern: ''
      },
      pluginObj: null,
      pluginSettingName: '',
      pluginName: '',
      pluginCredentialName: '',
      method: '',
      childEventIdPath: null,
      folder: '',
      locale: '',
      filters: {},
      source: '',
      attachments: [],
      includeHeaders: true,
      customDelimiter: '',
      delimiter: '',
      qualifier: '',
      cc: [],
      bcc: [],
      singleResult: false,
      command: '',
      resultCode: '',
      resultErrors: '',
      parameters: {},
      queryConditions: [],
      entries: [],
      projection: '',
      converts: [],
      librariesNames: [],
      key: '',
      xml: '',
      xslt: '',
      responseCode: '',
      encoding: 'UTF-8',
      sourceEncoding: '',
      targetEncoding: '',
      sourceObject: '',
      authType: '',
      skip: '',
      limit: ''
    },
    type: '',
    subType: '',
    actions: {
      rest: ['DELETE', 'GET', 'PATCH', 'POST', 'PUT'],
      oAuth: ['DELETE', 'GET', 'PATCH', 'POST', 'PUT'],
      mongoDb: ['FIND', 'INSERT', 'UPDATE', 'DELETE', 'COUNT'],
      jdbc: ['SELECT', 'UPDATE', 'INSERT', 'DELETE', 'CUSTOM', 'BEGIN TRANSACTION', 'COMMIT TRANSACTION', 'ROLLBACK TRANSACTION'],
      user: ['CHECK_EMAIL_AVAILABILITY', 'CREATE_USER', 'GENERATE_API_KEY', 'VALIDATE_API_KEY', 'VALIDATE_PASSWORD', 'GET_USER_DETAILS', 'GET_USER_ROLES', 'GET_USERS_BY_ROLES'].sort(),
      s3: ['CREATE', 'DELETE', 'LIST', 'READ'],
      security: ['DECRYPT', 'ENCRYPT'],
      imap: ['DELETE', 'MARK_READ', 'MARK_UNREAD', 'SEARCH', 'ATTACHMENTS'].sort(),
      csv: ['CSV_TO_JSON', 'JSON_TO_CSV'].sort(),
      image: ['RESCALE', 'DOWNLOAD', 'CONVERT', 'INFO', 'FROM_TEMPLATE'].sort(),
      languages: ['bg', 'zh', 'hr', 'cs', 'da', 'nl', 'en', 'et', 'fi', 'fr', 'de', 'el', 'hu', 'ga', 'it', 'lv', 'lt', 'mt', 'pl', 'pt', 'ro', 'ru', 'sr', 'sk', 'sl', 'es', 'sv', 'tr', 'uk'],
      paymentSensePac:['CANCEL_TRANSACTION', 'GET_REPORT', 'GET_TERMINAL', 'GET_TERMINALS', 'GET_TRANSACTION', 'SIGNATURE', 'START_REPORT', 'START_TRANSACTION'],
      paymentSenseConnectE:['ACCESS_TOKEN', 'CROSS_REFERENCE_PAYMENT', 'PAYMENT_DETAILS', 'PAYMENT_METHODS', 'RESUME_PAYMENT', 'REVOKE_ACCESS_TOKEN'],
      credential: ['CREATE', 'UPDATE', 'ENABLE', 'DISABLE'],
      zip: ['COMPRESS', 'DECOMPRESS'],
      encoder: definitions.EncoderStepProperties.properties.action.enum.sort(),
      executeProcess: ['ASYNC', 'SYNC', 'INLINE'],
      storage: ['GET', 'UPSERT'],
      formatting: ['DECIMAL_FORMAT'],
      smtp: ['ASYNC', 'SYNC'],
      ftp: ['LIST', 'DOWNLOAD', 'UPLOAD', 'DELETE'],
      xml: ['JSON_TO_XML', 'XML_TO_JSON']
    },
    fakeJdbcAction: '',
    KafkaMessagingStepConfig: {
      messageKey: ''
    },
    JmsMessagingStepConfig: {
      deliveryMode: '',
      priority: '',
      timeToLive: ''
    },
    RabbitmqMessagingStepConfig: {
      basicProperties: {
        appId: '',
        bodySize: '',
        clusterId: '',
        contentEncoding: '',
        contentType: '',
        correlationId: '',
        deliveryMode: '',
        expiration: '',
        headers: {},
        messageId: '',
        priority: '',
        replyTo: '',
        timestamp: '',
        type: '',
        userId: '',
        classId: '',
        className: ''
      },
      mandatory: false
    },
    MQTTMessagingStepConfig: {
      qos: '',
      retained: false
    },
    messagingServiceTypes: ['KAFKA', 'JMS', 'RABBITMQ', 'MQTT'],
    codeKey: 0,
    timeUnits: ['MSec', 'Sec', 'Min', 'Hr', 'Day'],
    timeUnit: 'Sec',
    tempTimeout: 0,
    scriptEngines: ['JS', 'GROOVY', 'PYTHON'],
    processId: 0,
    templateId: 0,
    localId: '',
    showGlobalEditor: false,
    isBodyScript: false,
    showSlackEditor: false,
    delimiters: definitions.CsvStepProperties.properties.delimiter.enum,
    qualifiers: definitions.CsvStepProperties.properties.qualifier.enum,
    imageFormats: definitions.ConvertImageStepFields.properties.type.enum,
    paymentSensePacReportTypes: ['BANKING', 'END_OF_DAY', 'X_BALANCE', 'Z_BALANCE'],
    paymentSensePacTransactionTypes: ['ACCOUNT_VERIFICATION', 'COMPLETION', 'DUPLICATE', 'PRE_AUTH', 'REFUND', 'SALE'],
    deliveryModes: definitions.JmsMessagingStepConfig.properties.deliveryMode.enum,
    credentialFieldsTypes: definitions.CreateCredentialFields.properties.type.enum,
    ocrLanguages: definitions.OcrStepProperties.properties.language.enum.sort(),
    showExecuteInputs: false,
    showExecuteOutputs: false,
    executeInputsKeyList: [],
    executeOutputsKeyList: [],
    isLoadingLibraries: false,
    libraries: [],
    searchLibraries: '',
    localesFormatting: [],
    encodingTypes: [],
    queryDelimiters: [',', 'AND', 'OR'],
    authTypes: ['NONE', 'BASIC', 'BEARER'],
    showProcessNameHint: false,
    isSftp: true
  }),
  computed: {
    formattedOcrLanguages() {
      return this.ocrLanguages.map((x) => {
        return { text: this.$lang.labels[x], value: x }
      }).sort()
    },
    priorities() {
      return Array.from({ length: 128 }, (_, index) => index + 1)
    },
    formattedXmlActions() {
      return this.actions.xml.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedFtpActions() {
      return this.actions.ftp.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedAuthTypes() {
      return this.authTypes.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedSmtpTypes() {
      return this.actions.smtp.map((x) => {
        return { text: this.$lang.status[x], value: x === 'ASYNC' }
      })
    },
    librariesFormatted() {
      const formattedLibrariesNames = this.properties?.librariesNames?.map((x) => {
        return { name: x, value: x }
      }) || []

      const allLib = this.libraries

      return [...allLib, ...formattedLibrariesNames] || []
    },
    availableLanguages() {
      return this.actions.languages.map((x) => {
        return { text: this.$lang.labels[x], value: x }
      })
    },
    formattedFormattingActions() {
      return this.actions.formatting.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedStorageActions() {
      return this.actions.storage.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedExecuteProcessActions() {
      return this.actions.executeProcess.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedEncoderActions() {
      return this.actions.encoder.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedZipActions() {
      return this.actions.zip.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedCredentialFieldsTypes() {
      return this.credentialFieldsTypes.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedCredentialActions() {
      return this.actions.credential.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedPaymentSenseConnectEActions() {
      return this.actions.paymentSenseConnectE.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedPaymentSensePacTransactionTypes() {
      return this.paymentSensePacTransactionTypes.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedPaymentSensePacReportTypes() {
      return this.paymentSensePacReportTypes.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedPaymentSensePacActions() {
      return this.actions.paymentSensePac.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedImageActions() {
      return this.actions.image.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedServices() {
      return this.messagingServiceTypes.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedScriptEngines() {
      return this.scriptEngines.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedUserActions() {
      return this.actions.user.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedS3Actions() {
      return this.actions.s3.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedSecurityActions() {
      return this.actions.security.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedImapActions() {
      return this.actions.imap.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedCsvActions() {
      return this.actions.csv.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    }
  },
  watch: {
    fakeJdbcAction: {
      handler(val) {
        if (val && ['BEGIN TRANSACTION', 'COMMIT TRANSACTION', 'ROLLBACK TRANSACTION'].includes(val)) {
          this.properties.query = this.fakeJdbcAction
        } else {
          this.properties.query = ''
        }
      }
    },
    searchLibraries: {
      handler(val) {
        if (val && val.length > 1) {
          this.searchLibrariesFunctions(val)
        }
      }
    },
    searchPluginSetting: {
      handler(val) {
        if (val && val.length > 1) {
          this.searchPluginSettings(val)
        }
      }
    },
    searchProcessSetting: {
      handler(val) {
        if (val && val.length > 1) {
          this.searchProcessesSettings(val)
        }
      }
    },
    searchProcesses: {
      handler(val) {
        if (val && val.length > 1) {
          this.searchProcessesFunction(val)
        } else {
          this.processId = 0
        }
      }
    },
    searchCredentials: {
      handler(val) {
        if (val && val.length > 1) {
          this.searchCredentialsFunction(val)
        }
      }
    },
    searchTemplates: {
      handler(val) {
        if (val && val.length > 1) {
          this.searchTextTemplatesFunction(val)
        } else {
          this.templateId = 0
        }
      }
    },
    searchPlugins: {
      handler(val) {
        if (val && val.length > 1) {
          this.searchPluginsFunction(val)
        }
      },
      immediate: true
    },
    timeUnit: {
      handler(val) {
        this.properties.timeout = this.$options.filters.unitFormatter(this.tempTimeout, val, 'MSec')
      }
    },
    tempTimeout: {
      handler(val) {
        this.properties.timeout = this.$options.filters.unitFormatter(val, this.timeUnit, 'MSec')
      }
    },
    type: {
      handler() {
        this.tempKey++
      }
    },
    step: {
      handler(val, oldval) {
        if (this.isNewSelected || !this.objectsEqual(val, oldval)) {
          this.init()
        }
      },
      deep: true
    },
    properties: {
      handler(val, oldVal) {
        if (!this.lock) {
          if (this.properties.childEventIdPath === '') {
            this.properties.childEventIdPath = null
          }
          if (this.step.type) {
            this.stepData.properties = this.properties
          }
          if (this.step.subType) {
            this.stepData = this.properties
          }
          this.$emit('saveSelected', this.stepData)
        }
      },
      deep: true
    },
    isTemplate: {
      handler(val) {
        if (val) this.properties.message = ''
        if (!val) {
          this.properties.textTemplate = ''
          this.properties.variables = {}
        }
      }
    }
  },
  created() {
    this.localesFormatting = localesFormatting
    if (!this.isNewSelected) this.init()
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),
    objectsEqual, arraysEqual,
    returnNameOrString(item) {
      return item && typeof item !== 'string' ? item.name : item
    },
    onChangeStorageAction() {
      if (this.properties.action === 'GET') {
        this.properties.fields = {
          targetObject: ''
        }
      } else {
        this.properties.fields = {
          expiresIn: '',
          value: ''
        }
      }
    },
    async fetchValidationRecursive(validation) {

      const pause = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

      const fetchedValidations = []
      const tempValidation = (validationId) => {
        getValidationRule({ id: validationId }).then((res) => {
          fetchedValidations.push(res.data.data.fields)
          if (res.data.data.includes && res.data.data.includes.length > 0) {
            res.data.data.includes.forEach((include) => {
              tempValidation(include)
            })
          }
        })
          .catch((err) => {
            console.log(err)
          })
      }

      await tempValidation(validation)

      await pause(1500)

      return fetchedValidations.flat(Infinity).map((x) => {
        return { ...x, name: `$.${x.name}` }
      })
    },
    onChangeProcessName() {
      this.executeInputsKeyList = []
      this.executeOutputsKeyList = []
      this.showExecuteInputs = false
      this.showExecuteOutputs = false
      this.showProcessNameHint = false
      const obj = {}

      obj.name = this.properties.processName

      if (!this.properties?.processName) return

      getProcesses(obj)
        .then((res) => {
          let foundInputValidations = null
          let foundOutputValidations = null
          const localProcess = res.data.data.items.find((x) => x.name === this.properties.processName)

          if (!localProcess) {
            this.isLoadingProcesses = false
            this.executeInputsKeyList = []
            this.executeOutputsKeyList = []
            this.showExecuteOutputs = true
            this.showExecuteInputs = true
            this.showProcessNameHint = true
            this.codeKey++

            return
          }

          getProcess({ id: localProcess.id })
            .then(async (res) => {
              if (res?.data?.data?.inputValidationRuleId) {
                foundInputValidations = await this.fetchValidationRecursive(res.data.data.inputValidationRuleId)
                this.executeInputsKeyList = foundInputValidations.map((x) => x.name)
                this.showExecuteInputs = true
                this.codeKey++
              } else {
                this.showExecuteInputs = true
                this.codeKey++
              }
              if (res?.data?.data?.outputValidationRuleId) {
                foundOutputValidations = await this.fetchValidationRecursive(res.data.data.outputValidationRuleId)
                this.executeOutputsKeyList = foundOutputValidations.map((x) => x.name)
                this.showExecuteOutputs = true
                this.codeKey++
              } else {
                this.showExecuteOutputs = true
                this.codeKey++
              }
            })
            .catch((err) => {
              this.isLoadingProcesses = false
              this.addSnackbar({
                message: err,
                timeout: 5000,
                color: 'error'
              })
            })
        })
        .catch((err) => {
          this.isLoadingProcesses = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    isJavaVariable(item) {
      if (!item) return false
      if (typeof item !== 'string') return false

      return item.substring(0, 1) === '$' || item.substring(0, 2) === '$.'
    },
    onChangeZipAction() {
      if (this.properties.action === 'COMPRESS') {
        this.properties.fields = {
          entries: []
        }
      }
      if (this.properties.action === 'DECOMPRESS ') {
        this.properties.fields = {
          sourceObject: ''
        }
      }
    },
    onChangeMessageService() {
      if (this.properties.service === 'JMS') {
        this.properties.config = this.JmsMessagingStepConfig
      }
      if (this.properties.service === 'KAFKA') {
        this.properties.config = this.KafkaMessagingStepConfig
      }
      if (this.properties.service === 'RABBITMQ') {
        this.properties.config = this.JmsMessagingStepConfig
      }
      if (this.properties.service === 'MQTT') {
        this.properties.config = this.MQTTMessagingStepConfig
      }
    },
    credentialActionChanged(newVal) {
      if (['CREATE'].includes(newVal)) {
        this.properties.fields = {
          name: '',
          roles: [],
          targetObject: '',
          type: '',
          values: {}
        }
      } else if (['UPDATE'].includes(newVal)) {
        this.properties.fields = {
          queryName: '',
          name: '',
          roles: [],
          type: '',
          values: {}
        }
      } else if (['DISABLE', 'ENABLE'].includes(newVal)) {
        this.properties.fields = {
          queryName: ''
        }
      }
    },
    paymentSenseConnectEActionChanged(newVal) {
      if (['PAYMENT_DETAILS', 'RESUME_PAYMENT', 'REVOKE_ACCESS_TOKEN'].includes(newVal)) {
        this.properties.parameters = {
          accessToken: ''
        }
      }
      if (['CROSS_REFERENCE_PAYMENT'].includes(newVal)) {
        this.properties.parameters = {
          accessToken: '',
          crossReferencePayment: {
            crossReference: '',
            cv2: ''
          }
        }
      }
      if (['ACCESS_TOKEN'].includes(newVal)) {
        this.properties.parameters = {
          amount: '',
          crossReference: '',
          currencyCode: '',
          customerId: '',
          merchantTransactionId: '',
          merchantUrl: '',
          metaData: {},
          newTransaction: true,
          orderDescription: '',
          orderId: '',
          shippingDetails: {
            address: {
              userAddress1: '',
              userAddress2: '',
              userAddress3: '',
              userAddress4: '',
              userCity: '',
              userCountryCode: '',
              userPostcode: '',
              userState: ''
            },
            name: ''
          },
          transactionSource: 'MOTO', // TODO make list
          transactionType: 'SALE', // TODO make list
          userAddress1: '',
          userAddress2: '',
          userAddress3: '',
          userAddress4: '',
          userAgent: '',
          userCity: '',
          userCountryCode: '',
          userEmailAddress: '',
          userIpAddress: '',
          userPhoneNumber: '',
          userPostcode: '',
          userState: '',
          waitPreExecute: false,
          webHookUrl: ''
        }
      }
      if (['PAYMENT_METHODS'].includes(newVal)) {
        this.properties.parameters = null
      }
    },
    paymentSensePacActionChanged(newVal) {
      if (['CANCEL_TRANSACTION', 'GET_TRANSACTION', 'GET_REPORT'].includes(newVal)) {
        this.properties.parameters = {
          requestId: '',
          terminalId: ''
        }
      }
      if (['GET_TERMINAL'].includes(newVal)) {
        this.properties.parameters = {
          terminalId: ''
        }
      }
      if (['START_TRANSACTION'].includes(newVal)) {
        this.properties.parameters = {
          requestId: '',
          terminalId: '',
          transaction: {
            amount: '',
            amountCashback: '',
            amountPreAuthorisedTotal: '',
            authCode: '',
            cardholderNotPresent: false,
            currency: '',
            transactionType: ''
          }
        }
      }
      if (['SIGNATURE'].includes(newVal)) {
        this.properties.parameters = {
          accepted: '',
          requestId: '',
          terminalId: ''
        }
      }
      if (['START_REPORT'].includes(newVal)) {
        this.properties.parameters = {
          reportType: '',
          terminalId: ''
        }
      }
      if (['GET_TERMINALS'].includes(newVal)) {
        this.properties.parameters = null
      }
    },
    onImageActionChange() {
      if (this.properties.action === 'RESCALE') {
        this.properties.fields = {
          width: '',
          height: ''
        }
      } else if (this.properties.action === 'CONVERT') {
        this.properties.fields = {
          type: ''
        }
      } else if (this.properties.action === 'FROM_TEMPLATE') {
        this.properties.fields = {
          type: '',
          width: '',
          height: '',
          locale: '',
          textTemplate: '',
          variables: {}

        }
      } else {
        this.properties.fields = {}
      }
    },
    emailRules(v) {
      const isSplit = v.split(',')

      if (isSplit.length > 1) {
        let correct = true

        isSplit.forEach((testEmail) => {
          if (correct) {
            correct = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(testEmail.trim().toLowerCase())
          }
        })

        if (correct) return true

        return false
      } else {
        return (v && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v.toLowerCase()))
      }
    },
    checkRegexFieldRecipient(val) {
      return this.$options.filters.checkComplexRegex(val)
    },
    async formatTimeUnits() {
      if (this.properties && this.properties.timeout) {
        const unitTempTimeout = await predictBestTimeUnitFromMsValue(this.properties.timeout)

        this.timeUnit = unitTempTimeout.unit
        this.tempTimeout = unitTempTimeout.value
      }
    },
    openStorage(key) {
      if (key && this.$route.params.id) {
        const replacedKey = key.replace(/'/g, '')

        getStorage({ processId: this.$route.params.id, key: replacedKey }).then((res) => {
          const findStorageId = res.data.data.items.find((x) => x.key === replacedKey)

          if (findStorageId) {
            window.open(`/${localStorage.selectedLanguage || 'en'}/storage/edit/${findStorageId.id}`, '_blank')
          } else {
            this.addSnackbar({
              message: this.$lang.errors.notFound,
              timeout: 5000,
              color: 'error'
            })
          }
        })
          .catch((err) => {
            this.addSnackbar({
              message: err,
              timeout: 5000,
              color: 'error'
            })
          })
      }
    },
    openProcess() {
      if (this.processId) window.open(`/${localStorage.selectedLanguage || 'en'}/processes/edit/${this.processId}`, '_blank')
    },
    openTemplate() {
      if (this.templateId) window.open(`/${localStorage.selectedLanguage || 'en'}/templates/templates/edit/${this.templateId}`, '_blank')
    },
    openPlugin() {
      if (this.properties.pluginObj) window.open(`/${localStorage.selectedLanguage || 'en'}/plugins/edit/${this.properties.pluginObj.id}`, '_blank')
    },
    openProcessSetting() {
      const obj = {}

      obj.name = this.properties.processSettingName

      getSettings(obj)
        .then((res) => {
          if (res && res.data && res.data.data && res.data.data.items && res.data.data.items.length === 1) {
            window.open(`/${localStorage.selectedLanguage || 'en'}/settings/edit/${res.data.data.items[0].id}`, '_blank')
          } else if (res && res.data && res.data.data && res.data.data.items && res.data.data.items.length > 1) {
            const filteredMatch = res.data.data.items.filter((x) => x.name === this.properties.processSettingName)

            if (filteredMatch.length === 1) {
              window.open(`/${localStorage.selectedLanguage || 'en'}/settings/edit/${filteredMatch[0].id}`, '_blank')
            } else {
              this.addSnackbar({
                message: this.$lang.errors.multipleSettings,
                timeout: 5000,
                color: 'error'
              })
            }
          } else {
            this.addSnackbar({
              message: this.$lang.errors.somethingWentWrong,
              timeout: 5000,
              color: 'error'
            })
          }
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })
        })
    },
    openCredential() {
      const obj = {}

      if (this.properties.credentialName.startsWith('$.')) {

        this.addSnackbar({
          message: this.$lang.errors.credentialVariableName,
          timeout: 5000,
          color: 'error'
        })

        return
      }

      obj.name = this.properties.credentialName

      if (this.type) obj.type = this.type

      if (this.type === 'EMAIL') obj.type = 'SMTP'

      if (this.type === 'PROCESS_SETTING') obj.type = 'PROCESS'

      if (this.type === 'S3') obj.type = 'AWS'

      if (this.type === 'JS' || this.type === 'GROOVY' || this.type === 'PYTHON') obj.type = 'SCRIPT'

      if (this.type === 'MESSAGING') obj.type = this.properties.service

      if (this.type === 'GRAPHQL') obj.type = 'REST'

      if (this.type === 'REST' && this.properties.authType === 'BASIC') obj.type = 'REST_BASIC'

      if (this.type === 'REST' && this.properties.authType === 'BEARER') obj.type = 'REST_BEARER'

      if (this.type === 'FTP' && this.isSftp) obj.type = 'SFTP'

      if (this.type === 'CHAT_GPT') obj.type = 'OPEN_AI'

      getCredentials(obj)
        .then((res) => {
          if (res && res.data && res.data.data && res.data.data.items && res.data.data.items.length === 1) {
            window.open(`/${localStorage.selectedLanguage || 'en'}/credentials/edit/${res.data.data.items[0].id}`, '_blank')
          } else if (res && res.data && res.data.data && res.data.data.items && res.data.data.items.length > 1) {
            const filteredMatch = res.data.data.items.filter((x) => x.name === this.properties.credentialName)

            if (filteredMatch.length === 1) {
              window.open(`/${localStorage.selectedLanguage || 'en'}/credentials/edit/${filteredMatch[0].id}`, '_blank')
            } else {
              this.addSnackbar({
                message: this.$lang.errors.multipleCredentials,
                timeout: 5000,
                color: 'error'
              })
            }
          } else {
            this.addSnackbar({
              message: this.$lang.errors.somethingWentWrong,
              timeout: 5000,
              color: 'error'
            })
          }
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })
        })
    },
    resetAsync() {
      if (this.properties.async) {
        this.properties.output = {}
        // this.properties.input = {}
        // this.properties.processName = ''
        this.tempKey++
      }
    },
    openEditor(event, type, language = null) {
      if (language)
        this.language = language
      if (type === 'bodyScript') {
        this.selectedItem = event
        this.isBodyScript = true
        this.showGlobalEditor = true

        return
      }
      if (['JS', 'GROOVY', 'PYTHON'].includes(this.type)) {
        this.selectedItem = event
        this.showJsGroovyEditor = true

        return
      }
      if (event.text) {
        this.selectedItem = { ...event, parentType: type }
        this.showEditor = true
      } else {
        this.$emit('showSnack', { text: this.$lang.hints.addKeyFirst, type: 'warning' })
      }
    },
    updateFromEditor(data) {
      if (this.isBodyScript) {
        this.properties.bodyScript = data
        this.isBodyScript = false
        this.codeKey++

        return
      }
      if (data.text) {
        this.properties[data.parentType][data.text] = data.value
      } else {
        this.properties[data.parentType] = data.value
      }
      this.codeKey++
    },
    init() {
      this.type = ''
      this.subType = ''
      this.lock = true

      if (this.step && this.step.type) {
        this.stepData = this.step
        this.properties = this.step.properties
        this.type = this.step.type
      }

      if (this.step && this.step.subType) {
        this.stepData = this.step
        this.properties = this.step
        this.subType = this.step.subType
      }

      this.formatTimeUnits()

      switch (this.type) {
      case 'CHAT_GPT':
        if (!this.properties.scriptEngine) this.properties.scriptEngine = 'JS'
        if (!this.properties.model) this.properties.model = 'gpt-4o'
        if (!this.properties.messages) this.properties.messages = []
        setTimeout(() => {
          this.codeKey = this.codeKey + 1
        }, 10)
        break
      case 'QR':
      case 'OCR':
        break
      case 'XML':
        if (!this.properties.action) this.properties.action = 'XML_TO_JSON'
        break
      case 'FTP':
        if (!this.properties.action) this.properties.action = 'DOWNLOAD'
        setTimeout(() => {
          this.searchCredentials = this.properties.credentialName
        }, 10)
        break
      case 'GRAPHQL':
        if (!this.properties.headers) this.properties.headers = {}
        setTimeout(() => {
          this.searchCredentials = this.properties.credentialName
        }, 10)
        if (!this.properties.timeout) this.tempTimeout = 15
        if (!this.properties.scriptEngine) this.properties.scriptEngine = 'JS'
        break
      case 'FORMATTING':
        if (!this.properties.action) {
          this.properties.action = 'DECIMAL_FORMAT'
          if (!this.properties.fields) this.properties.fields = {
            locale: '',
            pattern: ''
          }
        }
        break
      case 'STORAGE':
        if (!this.properties.action) {
          this.properties.action = 'GET'
          if (!this.properties.fields) this.properties.fields = {
            targetObject: ''
          }
        }
        if (!this.properties.scriptEngine) this.properties.scriptEngine = 'JS'
        break
      case 'ENCODER':
        getEncodingTypes()
          .then((res) => {
            this.encodingTypes = res.data.data.sort()
          })
          .catch((err) => {
            this.err = err
          })
        if (!this.properties.action) this.properties.action = 'ENCODE_HTML_SPECIAL_CHARS'
        if (!this.properties.converts) this.properties.converts = []
        break
      case 'ZIP':
        if (!this.properties.action) this.properties.action = 'COMPRESS'
        if (!this.properties.fields) this.properties.fields = {}
        if (!this.properties.fields) this.properties.fields = {}
        break
      case 'QUERY_BUILDER':
        if (!this.properties.scriptEngine) this.properties.scriptEngine = 'JS'
        if (!this.properties.queryConditions) this.properties.queryConditions = []
        break
      case 'CREDENTIAL':
        if (!this.properties.fields) this.properties.fields = {
          roles: []
        }
        break
      case 'PAYMENT_SENSE_CONNECT_E':
        if (!this.properties.parameters) this.properties.parameters = {
          accessToken: ''
        }
        if (this.properties.action === 'ACCESS_TOKEN') {
          if (!this.properties.parameters.metaData) this.properties.parameters.metaData = {}
          if (!this.properties.parameters.shippingDetails) this.properties.parameters.shippingDetails = {
            address: {
              userAddress1: '',
              userAddress2: '',
              userAddress3: '',
              userAddress4: '',
              userCity: '',
              userCountryCode: '',
              userPostcode: '',
              userState: ''
            },
            name: ''
          }
        }
        setTimeout(() => {
          this.searchCredentials = this.properties.credentialName
        }, 10)
        break
      case 'PAYMENT_SENSE_PAC':
        if (!this.properties.parameters) this.properties.parameters = {
          requestId: '',
          terminalId: ''
        }
        setTimeout(() => {
          this.searchCredentials = this.properties.credentialName
        }, 10)
        break
      case 'MESSAGING':
        if (!this.properties.scriptEngine) this.properties.scriptEngine = 'JS'
        setTimeout(() => {
          this.searchCredentials = this.properties.credentialName
        }, 10)
        if (!this.properties.service) this.properties.service = ''

        if (!this.properties.config) this.properties.config = {}

        if (this.properties.service === 'KAFKA' && !this.properties.config.messageKey) {
          this.properties.config = this.KafkaMessagingStepConfig
        }
        if (['JMS', 'RABBITMQ'].includes(this.properties.service) && this.properties.config.deliveryMode === undefined) {
          this.properties.config.deliveryMode = ''
        }
        if (['JMS', 'RABBITMQ'].includes(this.properties.service) && this.properties.config.priority === undefined) {
          this.properties.config.priority = ''
        }
        if (['JMS', 'RABBITMQ'].includes(this.properties.service) && this.properties.config.timeToLive === undefined) {
          this.properties.config.timeToLive = ''
        }
        break
      case 'EXECUTE_EXTERNAL_COMMAND':
        if (!this.properties.scriptEngine) this.properties.scriptEngine = 'JS'
        break
      case 'IMAGE':
        if (!this.properties.fields) this.properties.fields = {}
        setTimeout(() => {
          this.searchTemplates = this.properties.fields?.textTemplate
        }, 10)
        if (this.properties.fields?.textTemplate) this.isTemplate = true
        break
      case 'USER':
        if (!this.properties.fields) this.properties.fields = {}
        break
      case 'CSV':
        break
      case 'JDBC':
        setTimeout(() => {
          this.searchCredentials = this.properties.credentialName
        }, 10)
        if (['BEGIN TRANSACTION', 'COMMIT TRANSACTION', 'ROLLBACK TRANSACTION'].includes(this.properties.query)) {
          this.properties.action = this.properties.query
          this.fakeJdbcAction = this.properties.query
        }
        break
      case 'MONGODB':
        setTimeout(() => {
          this.searchCredentials = this.properties.credentialName
        }, 10)
        if (!this.properties.parameters) this.properties.parameters = {
          skip: '',
          limit: '',
          query: '',
          queryUpdate: '',
          sort: {
            field: '',
            direction: ''
          },
          projection: ''
        }
        break
      case 'EMAIL':
        setTimeout(() => {
          this.searchCredentials = this.properties.credentialName
          this.searchTemplates = this.properties.textTemplate
        }, 10)

        if (!this.properties.variables) this.properties.variables = {}
        if (!this.properties.variables.subject) this.properties.variables.subject = ''
        if (this.properties.locale) {
          this.properties.variables.locale = this.properties.locale
        }
        if (!this.properties.attachments) this.properties.attachments = []
        if (this.properties.cc && this.properties.cc.length > 0) {
          this.showCc = true
        }
        if (this.properties.bcc && this.properties.bcc.length > 0) {
          this.showBcc = true
        }
        if (![true, false].includes(this.properties.async)) {
          this.properties.async = true
        }
        break
      case 'SLACK':
        setTimeout(() => {
          this.searchCredentials = this.properties.credentialName
          this.searchTemplates = this.properties.textTemplate
        }, 10)
        if (this.properties.textTemplate) this.isTemplate = true
        if (this.properties.locale) {
          this.properties.variables.locale = this.properties.locale
        }
        if (!this.properties.scriptEngine) this.properties.scriptEngine = 'JS'
        break
      case 'JWT':
        setTimeout(() => {
          this.searchCredentials = this.properties.credentialName
        }, 10)
        break
      case 'REST':
        getEncodingTypes()
          .then((res) => {
            this.encodingTypes = res.data.data.sort()
          })
          .catch((err) => {
            this.err = err
          })
        if (!this.properties.headers) this.properties.headers = {}
        if (!this.properties.headers['Content-Type']) this.properties.headers['Content-Type'] = ''
        setTimeout(() => {
          this.searchCredentials = this.properties.credentialName
        }, 10)
        if (!this.properties.timeout) this.tempTimeout = 15
        if (!this.properties.scriptEngine) this.properties.scriptEngine = 'JS'
        if (!this.properties.authType) this.properties.authType = 'NONE'
        break
      case 'FOREACH':
        break
      case 'EXECUTE_PROCESS':
        setTimeout(() => {
          this.searchProcesses = this.properties.processName
        }, 10)
        if (this.properties.processName) this.onChangeProcessName()
        if (!this.properties.output) this.properties.output = {}
        if (!this.properties.input) this.properties.input = {}
        if (!this.properties.action) this.properties.action = 'SYNC'
        break
      case 'TWILIO':
        setTimeout(() => {
          this.searchCredentials = this.properties.credentialName
          this.searchTemplates = this.properties.textTemplate
        }, 10)
        this.searchTemplates = this.properties.textTemplate
        if (this.properties.textTemplate) this.isTemplate = true
        if (this.properties.locale) {
          this.properties.variables.locale = this.properties.locale
        }
        if (!this.properties.scriptEngine) this.properties.scriptEngine = 'JS'
        break
      case 'PROCESS_SETTING':
        setTimeout(() => {
          this.searchProcessSetting = this.properties.processSettingName
        }, 10)
        break
      case 'PLUGIN':
        this.searchPlugins = null
        this.plugins = []
        this.searchCredentials = null
        this.credentials = []
        setTimeout(() => {
          this.searchPlugins = this.properties.pluginName
          this.searchPluginSetting = this.properties.pluginSettingName
          this.searchCredentials = this.properties.pluginCredentialName
        }, 10)
        break
      case 'S3':
        setTimeout(() => {
          this.searchCredentials = this.properties.credentialName
        }, 10)
        break
      case 'JS':
      case 'GROOVY':
        setTimeout(() => {
          this.searchCredentials = this.properties.credentialName
        }, 10)
        if (!this.properties.librariesNames) this.properties.librariesNames = []
        break
      case 'PYTHON':
        setTimeout(() => {
          this.searchCredentials = this.properties.credentialName
        }, 10)
        if (!this.properties.librariesNames) this.properties.librariesNames = []
        if (!this.properties.set) this.properties.set = {}
        break
      case 'UNSET_VARIABLES':
        if (!this.properties) this.properties = { variables: [] }
        break
      case 'SECURITY':
        setTimeout(() => {
          this.searchCredentials = this.properties.credentialName
        }, 10)
        break
      case 'IMAP':
        setTimeout(() => {
          this.searchCredentials = this.properties.credentialName
        }, 10)
        if (!this.properties.scriptEngine) this.properties.scriptEngine = 'JS'
        break
      case 'PDF':
        if (!this.properties.variables) this.properties.variables = {}
        setTimeout(() => {
          this.searchTemplates = this.properties.textTemplate
        }, 10)
        break
      case 'WHILE':
        if (!this.properties.scriptEngine) this.properties.scriptEngine = 'JS'
        break
      case 'SWITCH':
        if (!this.properties.scriptEngine) this.properties.scriptEngine = 'JS'
        break
      default:
      }

      this.tempKey++
      this.lock = false
    },
    previewData(item) {
      this.$emit('previewData', item)
    },
    searchPluginsFunction(val = '') {
      this.isLoadingPlugins = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getPlugins(obj)
        .then((res) => {
          this.plugins = res.data.data.items
          this.isLoadingPlugins = false

          const isSelected = res.data.data.items.find((x) => x.name === this.searchPlugins)

          if (isSelected) this.properties.pluginObj = isSelected

          if (isSelected) this.properties.pluginName = isSelected.name
        })
        .catch((err) => {
          this.isLoadingPlugins = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    searchTextTemplatesFunction(val = '') {
      this.isLoadingTemplates = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getTemplates(obj)
        .then((res) => {
          this.templates = res.data.data.items
          this.isLoadingTemplates = false

          const isSelected = res.data.data.items.find((x) => x.name === this.searchTemplates)

          if (isSelected) this.templateId = isSelected.id
        })
        .catch((err) => {
          this.isLoadingTemplates = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })         })
    },
    searchProcessesFunction(val = '') {
      this.isLoadingTemplates = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      obj.isSystem = this.resourceIsSystem

      getProcesses(obj)
        .then((res) => {
          this.processes = res.data.data.items
          this.isLoadingProcesses = false

          const isSelected = res.data.data.items.find((x) => x.name === this.searchProcesses)

          if (isSelected) this.processId = isSelected.id
        })
        .catch((err) => {
          this.isLoadingProcesses = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })         })
    },
    searchCredentialsFunction(val = '') {
      if (this.isJavaVariable(val)) return

      this.isLoadingCredentials = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      if (this.type) obj.type = this.type

      if (this.type === 'EMAIL') obj.type = 'SMTP'

      if (this.type === 'PROCESS_SETTING') obj.type = 'PROCESS'

      if (this.type === 'S3') obj.type = 'AWS'

      if (this.type === 'JS' || this.type === 'GROOVY' || this.type === 'PYTHON') obj.type = 'SCRIPT'

      if (this.type === 'MESSAGING') obj.type = this.properties.service

      if (this.type === 'GRAPHQL') obj.type = obj.type = 'REST'

      if (this.type === 'REST' && this.properties.authType === 'BASIC') obj.type = 'REST_BASIC'

      if (this.type === 'REST' && this.properties.authType === 'BEARER') obj.type = 'REST_BEARER'

      if (this.type === 'FTP' && this.isSftp) obj.type = 'SFTP'

      if (this.type === 'CHAT_GPT') obj.type = 'OPEN_AI'

      getCredentials(obj)
        .then((res) => {
          this.credentials = res.data.data.items
          this.isLoadingCredentials = false

          if (res.data.data.items.length === 0 && this.type === 'FTP' && this.isSftp) {
            this.isSftp = false
            this.searchCredentialsFunction(val)
          }
        })
        .catch((err) => {
          this.isLoadingCredentials = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })         })
    },
    searchProcessesSettings(val = '') {
      this.isLoadingProcessSetting = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      obj.type = 'PROCESS'

      getSettings(obj)
        .then((res) => {
          this.settings = res.data.data.items
          this.isLoadingProcessSetting = false
        })
        .catch((error) => {
          this.isLoadingProcessSetting = false
          console.log(error)
        })
    },
    searchPluginSettings(val = '') {
      this.isLoadingPluginSetting = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      obj.type = 'PLUGIN'

      getSettings(obj)
        .then((res) => {
          this.settings = res.data.data.items
          this.isLoadingPluginSetting = false
        })
        .catch((error) => {
          this.isLoadingPluginSetting = false
          console.log(error)
        })
    },
    searchLibrariesFunctions(val = '') {
      this.isLoadingLibraries = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      // obj.type = this.type

      getLibraries(obj)
        .then((res) => {
          this.libraries = res.data.data.items
          this.isLoadingLibraries = false
        })
        .catch((error) => {
          this.isLoadingLibraries = false
          console.log(error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.button-help {
  opacity: 0.6;
}

.button-help:hover {
  opacity: 1;
}
</style>
