<template>
  <v-card class="pa-2" style="min-height: 80vh; max-height: 80vh; overflow-y: hidden">
    <v-row v-if="success" no-gutters>
      <v-col cols="12">
        <v-alert color="success" :data-cy="success">{{ success }}</v-alert>
      </v-col>
    </v-row>
    <v-card-title class="pb-2">
      <span>{{ $lang.header.cache }}</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        color="primary"
        text
        large
        @click="$emit('closeDialog', true)"
      >
        X
      </v-btn>
    </v-card-title>
    <v-row no-gutters>
      <v-col cols="12">
        <v-tabs
          v-model="tab"
        >
          <v-tabs-slider color="accent"></v-tabs-slider>

          <v-tab
            :key="0"
          >
            {{ $lang.labels.list }}
          </v-tab>
          <v-tab
            :key="1"
          >
            {{ $lang.labels.json }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
            :key="0"
            eager
          >
            <div style="width: 100%; max-height: 63vh; overflow-y: auto" class="py-2">
              <template v-for="(item, i) in tempArr">
                <span v-if="item.value === ''" :key="i" class="headline">{{ `${item.text} / ` }}</span>
                <p v-else :key="i" class="color-secondary">{{ item.text ? `${item.text}: ` : '' }} <span class="color-primary">{{ item.value }}</span></p>
              </template>
            </div>
          </v-tab-item>
          <v-tab-item
            :key="1"
            eager
          >
            <v-lazy style="width: 100%; max-height: 63vh; overflow-y: auto" class="py-2">
              <json-custom-viewer :data="data" @success="success = $event" />
            </v-lazy>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import jsonCustomViewer from '@/components/ui/JsonCustomViewer'
export default {
  components: {
    jsonCustomViewer
  },
  props: {
    data: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      tab: 0,
      tempArr: [],
      success: ''
    }
  },
  created() {
    const formatObj = (obj) => {
      if (obj && Object.keys(obj).length > 0) {
        for (const row in obj) {
          // eslint-disable-next-line no-prototype-builtins
          if (obj.hasOwnProperty(row)) {
            if (Array.isArray(obj[row])) {
              this.tempArr.push({ text: row , value: '' })
              obj[row].forEach((item) => {
                if (typeof  item !== 'object') {
                  this.tempArr.push({ text: '' , value: item })
                } else if (item && typeof  item === 'object' && Object.keys(item).length > 0) {
                  // this.tempArr.push({ text: item , value: '' })
                  formatObj(item)
                } else if (item && typeof  item === 'object' && Object.keys(item).length === 0) {
                  // this.tempArr.push({ text: item , value: '{}' })
                } else {
                  this.tempArr.push({ text: item.toString() , value: 'null' })
                }
              })
            } else if (typeof  obj[row] !== 'object') {
              this.tempArr.push({ text: row , value: obj[row] })
            } else if (obj[row] && typeof  obj[row] === 'object' && Object.keys(obj[row]).length > 0) {
              this.tempArr.push({ text: row , value: '' })
              formatObj(obj[row])
            } else if (obj[row] && typeof  obj[row] === 'object' && Object.keys(obj[row]).length === 0) {
              this.tempArr.push({ text: row , value: '{}' })
            } else {
              this.tempArr.push({ text: row , value: 'null' })
            }
          }
        }
      }
    }

    if (this.data) {
      formatObj(this.data)
    }
  },
  methods: {}
}
</script>
<style lang="scss">
.v-lazy {
  max-height: 60vh;
}
#data-text {
  height: 57vh !important;
}
</style>
