<template>
  <div class="border-class pa-1">
    <v-row no-gutters align="center">
      <v-col cols="6">
        <h3>{{ $lang.labels[type] }}</h3>
      </v-col>
      <v-col cols="6" class="text-right">
        <v-btn
          icon
          :outlined="$vuetify.theme.dark"
          small
          class="color-secondary bg-outline-color"
          :disabled="!canEdit"
          @click="deconstructedData.push({ sourceObject: '', targetObject: '' })"
        >
          +
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" class="pt-2">
      <v-col cols="12">
        <v-row no-gutters align="center" class="pb-1">
          <v-col v-if="userSettings.display.showId" cols="1">
            <span>{{ $lang.header.id }}</span>
          </v-col>
          <v-col cols="5" class="px-1">
            <span>{{ $lang.header.sourceObject }}</span>
          </v-col>
          <v-col cols="5" class="px-1">
            <span>{{ $lang.header.targetObject }}</span>
          </v-col>
          <v-col cols="1" class="text-right">
            <span>{{ $lang.header.act }}</span>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <template v-for="(item, i) in deconstructedData">
          <v-row :key="i" no-gutters align="center" class="pb-1">
            <v-col v-if="userSettings.display.showId" cols="1">
              <span>{{ i + 1 }}</span>
            </v-col>
            <v-col cols="5" class="px-1">
              <v-text-field
                v-model="deconstructedData[i].sourceObject"
                dense
                required
                :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
                :readonly="!canEdit"
                :data-cy="`${type}-sourceObject-${i}`"
              ></v-text-field>
            </v-col>
            <v-col cols="5" class="px-1">
              <v-text-field
                v-model="deconstructedData[i].targetObject"
                dense
                required
                :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
                :readonly="!canEdit"
                :data-cy="`${type}-targetObject-${i}`"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn
                class="ml-1"
                icon
                small
                :disabled="!canEdit"
                @click="deleteRow(i)"
              >
                <v-icon small color="error">mdi-trash-can-outline</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    type: {
      type: String,
      default: () => {
        return ''
      }
    },
    stepType: {
      type: String,
      default: () => {
        return ''
      }
    },
    canEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data: () => ({
    deconstructedData: [],
    duplicated: false
  }),
  computed: {
    ...mapState('app', ['userSettings'])
  },
  watch: {
    deconstructedData: {
      handler(val) {
        this.$emit('dataChanged', val)
      },
      deep: true
    }
  },
  created() {
    if (this.data && this.data.length > 0) {
      this.deconstructedData = this.data
    }
  },
  methods: {
    deleteRow(i) {
      this.deconstructedData.splice(i, 1)
    }
  }
}
</script>
<style lang="scss" scoped>
.border-class {
  border: 1px var(--v-customInputBorderColor-base) solid;
  border-radius: 6px;
}
</style>
